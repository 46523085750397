import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnChanges,
} from "@angular/core";
import {
  Routes,
  NavigationExtras,
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import { TestSuiteService } from "../../../services/test-suite.service";
import { RunService } from "../../../services/run.service";
import { ProjectService } from "../../../services/project.service";
import { Base } from "../../../models/base.model";
// import { MatSort, MatSortable, MatTableDataSource } from '@angular/material';
import { Handler } from "../../dialogs/handler/handler";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AutoSyncComponent } from "../../dialogs/auto-sync/auto-sync.component";
import { Subject, Subscription } from "rxjs";
import { ProjectSync } from "../../../models/project-sync.model";
import { SnackbarService } from "../../../services/snackbar.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TestsuitEditDialogComponent } from "../../dialogs/testsuit-edit-dialog/testsuit-edit-dialog.component";
import { TestsuitNewDialogComponent } from "../../dialogs/testsuit-new-dialog/testsuit-new-dialog.component";
import { SuggestionCounts } from "../../../models/project.model";
import { GlobalProjectDetails } from "../../../shared/shared.data";

import {
  ELASTICSEARCHVALIDATOR,
  GlobalPlaybookFlag,
} from "./../../../shared/shared.data";
import { AutoCodeConfig } from "../../../models/project-autocode-config.model";
// import { AutoSyncSaveConfigComponent } from "../../dialogs/auto-sync-save-config/auto-sync-save-config.component";
// import { ChartOptions, ChartType, ChartDataSets, Label, Color } from "chart.js";
import { CHARTCONFIG } from "./../../../charts/charts.config";
import { DatePipe } from "@angular/common";
import { PlaybookSampleDialogComponent } from "./../../dialogs/playbook-sample-dialog/playbook-sample-dialog.component";
// import { ChartsModule } from "../../../../../node_modules/ng2-charts";
import { TasksService } from "../../../services/tasks.service";
import { timer } from "rxjs";
import * as echarts from "echarts";
import { GlobalSelectedEnv } from "../../../shared/shared.data";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
@Component({
  template: "",
})
export class DialogEntryComponent {
  playbookCreatedFlag = GlobalPlaybookFlag;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.openDialog();
  }
  projectId;
  playbookName;

  openDialog(): void {
    this.projectId = this.router.url.split("/")[3];
    this.route.params.subscribe((params) => {
      this.playbookName = params["playbookName"];
    });
    const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
      width: "1400px",
      maxWidth: "90vw",
      data: [this.projectId, "", this.playbookName, true],
    });

    dialogRef.componentInstance.emitData.subscribe((result) => {
      if (result[0] == this.projectId && result != null && result.length > 1) {
        this.playbookCreatedFlag.playbookListFlag = "YY";
        this.playbookCreatedFlag.playbookData = result[1];
      } else if (
        result[0] == this.projectId &&
        result != null &&
        result.length == 1
      ) {
        this.playbookCreatedFlag.playbookListFlag = "Y";
        this.playbookCreatedFlag.playbookData = "";
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      let navigationExtras: NavigationExtras = {
        queryParams: { return: "list" },
      };
      this.router.navigate(
        ["/app/projects", this.projectId, "playbooks"],
        navigationExtras
      );
      // this.router.navigate(["../"], { relativeTo: this.route });
    });
  }
}

@Component({
  selector: "app-test-suite-list",
  templateUrl: "./test-suite-list.component.html",
  styleUrls: ["./test-suite-list.component.scss"],
  providers: [
    TestSuiteService,
    RunService,
    ProjectService,
    SnackbarService,
    DatePipe,
    TasksService,
  ],
})
export class TestSuiteListComponent implements OnInit {
  playbookCreatedFlag = GlobalPlaybookFlag;
  taskStatus = "In_progress";
  tasksRes: any;
  subscription: Subscription;
  id; // project id
  testsuites;
  renderedData: any = null;
  reviewStatus: any = "";
  // projectId: string = "";
  project: Base = new Base();
  showSpinner: boolean = false;
  keyword: string = "";
  category: string = "All";
  suites;
  projectSync: ProjectSync = new ProjectSync();
  // private _clockSubscription: Subscription;
  selectedCategories: string;
  searchStringValidator = ELASTICSEARCHVALIDATOR;
  config = CHARTCONFIG;
  snapshotCount = [];
  categoryCountType = [];
  tsCount = [];
  snapShotDate = [];
  playbookStatusFlag: boolean = true;
  // @Input() playbookStatusFlag: boolean = true;
  displayedColumns: string[] = [
    "name",
    "path",
    "category",
    "authors",
    "reviewStatus",
    "autoGenerated",
    "inactive",
    "modifiedDate",
  ];
  dataSource = null;
  length = 0;
  page = 0;
  pageSize = 25;
  pageIndex = 0;
  totActGenerators = 0;
  currentCategory;
  categoryCount = [];
  categoryContByStatus: any = [];
  autoCodeSyncObj = [];
  autoCodeSyncDeleteObj = [];
  categoryByManaged: any = [];
  autoCodeConfig: AutoCodeConfig = new AutoCodeConfig();
  data1 = [140, 112, 125, 100, 126, 110, 136];
  last7daysList = [];
  sortString: string = "modifiedDate";
  sortType: string = "DESC";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageSizeOptions: number[] = [25, 50, 100];
  resourceSamples: any;
  disableSyncButton: boolean;
  progressFlag: boolean;
  dataLoad: boolean;
  activeFlag: boolean;
  count: number;
  totalEndpoints: number = 0;
  destroyFlag: boolean = false;
  private _clockSubscription: Subscription;

  totalCurrentScripts = 0;
  totalExpectedScripts = 0;
  playbooksData: any;
  dayslist = [];
  playbookList = [];
  PlaybookChart: any;
  PlaybookChart3: any;
  selectedCategory: any;
  ccArray2 = [];
  myChart: any;
  pixelWidth;
  storedStatus: any;
  globalSelectedEnv = GlobalSelectedEnv;
  categoriesGroup = [
    {
      name: "Proofread",
      status: [{ value: "" }],
    },
    {
      name: "Managed",
      status: [{ value: "" }],
    },
    {
      name: "Category",
      status: [{ value: "" }],
    },
  ];
  mobile: boolean = false;
  testsuitesNames: any = [];
  private chart: any;
  isSort: boolean = true;
  autoGenerated: any = "";
  constructor(
    private testSuiteService: TestSuiteService,
    private runService: RunService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private handler: Handler,
    private snackbarService: SnackbarService,
    private datePipe: DatePipe,
    private tasksService: TasksService,
    private el: ElementRef
  ) {
    // this.category = "All";
  }

  ngOnInit() {
    // Scroll to top on route change
    // this.router.events.subscribe(evt => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }

    // });

    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(700), 
        distinctUntilChanged() 
      )
      .subscribe((keyword) => {
        this.searchSubject.next(this.keyword)
        this.search(event);
      });


    this.route.queryParams.subscribe((qp) => {
      if (qp["return"] == "list") {
        this.searchByCategory();
        setTimeout((s) => {
          this.getPlaybookCreationLoader();
        }, 2000);
      }
    });

    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.getPlaybookCreationStatus();
      this.loadProject(this.id);
      this.getAutoGeneratedCounts();
      this.searchByCategory();
      this.playbookCountSnapshot(this.id);
      this.getCounts();
      this.getCategoryCountsByStatus();
      this.getPlaybookCreationLoader();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd == true) {
        if (this.playbookCreatedFlag.playbookListFlag == "Y") {
          this.playbookCreatedFlag.playbookListFlag = "";
          this.searchCounter = 0;
          this.refresh();
        } else if (this.playbookCreatedFlag.playbookListFlag == "YY") {
          var projectIdTmp = event["url"].split("/")[3];
          this.playbookCreatedFlag.playbookListFlag = "";
          const dialogRef2 = this.dialog.open(TestsuitNewDialogComponent, {
            width: "1400px",
            maxWidth: "90vw",
            data: [projectIdTmp, this.playbookCreatedFlag.playbookData],
          });
          dialogRef2.componentInstance.emitData.subscribe((result) => {
            if (
              result[0] == projectIdTmp &&
              result != null &&
              result.length == 1
            ) {
              this.playbookCreatedFlag.playbookListFlag = "";
              this.playbookCreatedFlag.playbookData = "";
              this.refresh();
            }
          });
        }
      }
    });

    this.getplaybooksCountApi(this.id);
    const tsName = localStorage.getItem("suitename");
    if (tsName != null) {
      this.showTSDialog("", tsName, true);
    }
    this.pixelWidth = window.screen.width;
    if (this.pixelWidth <= 1000) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  AutoGeneratedCountData: any = [];
  getAutoGeneratedCounts() {
    this.handler.activateLoader();
    this.testSuiteService.getAutoGeneratedCounts(this.id).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.AutoGeneratedCountData = results["data"];

        if (
          this.AutoGeneratedCountData &&
          this.AutoGeneratedCountData.length > 0
        ) {
          var autoGenerated = null;
          for (let i = 0; i < this.AutoGeneratedCountData.length; i++) {
            if (this.AutoGeneratedCountData[i].groupBy == false) {
              autoGenerated = { groupBy: "", label: "", count: 0 };
              autoGenerated["groupBy"] = "User";
              autoGenerated["label"] = "User";
              autoGenerated["count"] += this.AutoGeneratedCountData[i].count;
            }
            if (this.AutoGeneratedCountData[i].groupBy == true) {
              autoGenerated = { groupBy: "", label: "", count: 0 };
              autoGenerated["groupBy"] = "Bot";
              autoGenerated["label"] = "Bot";
              autoGenerated["count"] += this.AutoGeneratedCountData[i].count;
            }
            this.categoryByManaged.push(autoGenerated);
          }
        }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  getPlaybookCreationLoader() {
    // this.taskStatus = 'Done';
    // this.getPlaybookCreationStatus();

    if (this.taskStatus != "In_progress")
      this.taskStatus = this.isPrpegated("");

    setTimeout((s) => {
      if (this.taskStatus == "In_progress" && this.destroyFlag == false) {
        let timer1 = timer(5000, 15000);
        this._clockSubscription = timer1.subscribe((t) => {
          this.getPlaybookCreationStatus();
          this.categoryCountType = [];
          this.searchByCategory();
          this.getCounts();
          this.playbookCountSnapshot(this.id);
          this.getCategoryCountsByStatus();
        });
      }
    }, 2000);
  }

  getplaybooksCountApi(id) {
    this.handler.activateLoader();
    this.testSuiteService.getPlaybooksCounts(id).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.playbooksData = results["data"];
        if (this.playbooksData && this.playbooksData.length > 0) {
          for (let i = 0; i < this.playbooksData.length; i++) {
            if (this.playbooksData[i].day) {
              this.dayslist.push(this.playbooksData[i].day);
            }

            if (this.playbooksData[i].playbookCount) {
              this.playbookList.push(this.playbooksData[i].playbookCount);
            } else {
              this.playbooksData[i].playbookCount = 0;
              this.playbookList.push(this.playbooksData[i].playbookCount);
            }
          }
        }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  ngOnDestroy() {
    // if (this._clockSubscription !== undefined) {
    //   this._clockSubscription.unsubscribe();
    // }
    this.destroyFlag = true;
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    // console.log("inside destroy", this._clockSubscription)
    if (this._clockSubscription) this._clockSubscription.unsubscribe();
    else {
      setTimeout((s) => {
        if (this._clockSubscription) {
          // console.log("inside If destroy", this._clockSubscription)
          this._clockSubscription.unsubscribe();
        }
      }, 5000);
    }
    localStorage.removeItem("selCategory");
    localStorage.removeItem("suitename");
    localStorage.removeItem("testsuitesNames");
  }
  globalProjectDetails = GlobalProjectDetails;
  loadProject(id: string) {
    this.handler.activateLoader();
    this.projectService.getById(id).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.project = results["data"];
        this.globalProjectDetails.name = this.project.name;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  list(id: string) {
    this.handler.activateLoader();
    this.testSuiteService.getById(id, this.page, this.pageSize).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.testsuites = results["data"];
        this.length = results["totalElements"];
        this.dataSource = new MatTableDataSource(this.testsuites);
        this.dataSource.sort = this.sort;
        this.dataSource.connect().subscribe((d) => (this.renderedData = d));
        this.dataSource.connect().subscribe((d) => {
          if (this.renderedData) {
            this.testsuitesNames = null;
            this.testsuitesNames = this.renderedData.map(function (item) {
              return item.name;
            });
            localStorage.setItem("testsuitesNames", this.testsuitesNames);
          }
        });
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  change(evt) {
    this.page = evt["pageIndex"];
    this.pageSize = evt.pageSize;
    this.searchByCategory();
  }

  searchSubject = new Subject<string>();
  searchSubscription: Subscription;
  resultOfPlaybooks: boolean = true;

  onSearchInput(event) {
    const inputElement = event.target as HTMLInputElement;
    const key = event.keyCode;
    if (this.searchStringValidator.isValidSearchKeyCode(key)){
      this.searchSubject.next(inputElement.value);
    }    
  }

  clearSearch(){
    this.keyword = '';
    this.onSearchInput({target:{value:''}} as any)
  }

  search(event) {
    this.resultOfPlaybooks = false;
    this.keyword = this.searchStringValidator.checkSearchString(this.keyword);
    let regExp = /[{()}]/g;
    this.keyword = this.keyword.replace(regExp, "");
    // const key = event.keyCode;
    // if (this.searchStringValidator.isValidSearchKeyCode(key)) {
      this.page = 0;
      if (this.keyword === "" && this.category === "") {
        return this.list(this.id);
        this.resultOfPlaybooks = true;
      }
      let category_ = "";
      if (this.category === "All") {
        category_ = "";
      } else {
        category_ = this.category;
      }
      if (
        this.categoryContByStatus.some(
          (categoryName) => categoryName.groupBy === this.category
        )
      ) {
        this.reviewStatus = this.category;
        if (this.reviewStatus === "All") {
          this.reviewStatus = "";
        }
        if (this.reviewStatus === "ERROR") {
          this.reviewStatus = "PENDING";
        }
        category_ = "";
        this.autoGenerated = "";
      }

      if (
        this.categoryByManaged.some(
          (categoryName) => categoryName.groupBy === this.category
        )
      ) {
        this.autoGenerated = this.category;
        category_ = "";
        this.reviewStatus = "";
      }

      if (this.keyword.length > 0 || this.keyword.length === 0) {
        if (this.autoGenerated == "Bot") {
          this.autoGenerated = true;
        }
        if (this.autoGenerated == "User") {
          this.autoGenerated = false;
        }

        this.handler.activateLoader();
        this.testSuiteService
          .searchTestSuiteV2(
            this.id,
            category_,
            this.reviewStatus,
            this.autoGenerated,
            this.keyword,
            this.page,
            this.pageSize,
            this.sortString,
            this.sortType
          )
          .subscribe(
            (results) => {
              this.handler.hideLoader();
              this.resultOfPlaybooks = true;
              if (this.handler.handle(results)) {
                return;
              }
              this.testsuites = results["data"];
              this.length = results["totalElements"];
              this.dataSource = new MatTableDataSource(this.testsuites);
              this.dataSource.sort = this.sort;
              this.dataSource
                .connect()
                .subscribe((d) => (this.renderedData = d));
              this.dataSource.connect().subscribe((d) => {
                if (this.renderedData) {
                  this.testsuitesNames = null;
                  this.testsuitesNames = this.renderedData.map(function (item) {
                    return item.name;
                  });
                  localStorage.setItem("testsuitesNames", this.testsuitesNames);
                }
              });
            },
            (error) => {
              this.handler.hideLoader();
              this.handler.error(error);
            }
          );
      }
    // }
  }

  tabChangeListner(event) {
    console.log(event);
  }

  searchCounter = 0;

  searchByCategory() {
    this.categoryCountType = [];
    this.reviewStatus = "";
    this.autoGenerated = "";
    //check if user wants to see error playbooks
    if (localStorage.getItem("playbookType") == "error") {
      this.reviewStatus = "ERROR";
      this.playbookStatusFlag = false;
      localStorage.removeItem("playbookType");
    }

    this.handler.activateLoader();
    if (this.keyword === "" && this.category === "") {
      return this.list(this.id);
    }
    let category_ = "";
    if (this.category === "All") {
      category_ = "";
      this.autoGenerated = "";
    } else {
      category_ = this.category;
    }

    if (!this.category.match(this.currentCategory)) {
      this.page = 0;
      this.pageIndex = 0;
    }
    if (
      this.categoryContByStatus.some(
        (categoryName) => categoryName.groupBy === this.category
      )
    ) {
      this.reviewStatus = this.category;
      if (this.reviewStatus === "All") {
        this.reviewStatus = "";
      }
      if (this.reviewStatus === "ERROR") {
        this.reviewStatus = "PENDING";
      }
      category_ = "";
      this.autoGenerated = "";
    }

    if (
      this.categoryByManaged.some(
        (categoryName) => categoryName.groupBy === this.category
      )
    ) {
      this.autoGenerated = this.category;
      category_ = "";
      this.reviewStatus = "";
    }

    // if (this.reviewStatus == "")
    //   this.reviewStatus = this.category;

    if (this.reviewStatus == "All") {
      this.reviewStatus = "";
      this.autoGenerated = "";
    }

    if (this.reviewStatus === "ERROR") {
      this.reviewStatus = "PENDING";
      this.category = "ERROR";
    }

    //change toggle button on selection of dropdown other than Error
    if (this.reviewStatus != "PENDING") this.playbookStatusFlag = true;
    else if (this.reviewStatus == "PENDING") {
      this.playbookStatusFlag = false;
    }

    if (
      this.currentCategory == "ERROR" &&
      (this.reviewStatus == "AUTO" ||
        this.reviewStatus == "NEW" ||
        this.reviewStatus == "MANUAL" ||
        this.reviewStatus == "")
    ) {
      this.getCounts();
    } else if (
      this.reviewStatus == "PENDING" &&
      (this.currentCategory == "AUTO" ||
        this.currentCategory == "NEW" ||
        this.currentCategory == "MANUAL" ||
        this.currentCategory == "All")
    ) {
      this.getCounts();
    }
    if (this.autoGenerated == "Bot") {
      this.autoGenerated = true;
    }
    if (this.autoGenerated == "User") {
      this.autoGenerated = false;
    }

    this.testSuiteService
      .searchTestSuiteV2(
        this.id,
        category_,
        this.reviewStatus,
        this.autoGenerated,
        this.keyword,
        this.page,
        this.pageSize,
        this.sortString,
        this.sortType
      )
      .subscribe(
        (results) => {
          this.handler.hideLoader();
          if (this.handler.handle(results)) {
            return;
          }
          this.testsuites = results["data"];
          this.length = results["totalElements"];
          this.categoryCount.sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          if (!this.category.match(this.currentCategory) && this.page !== 0) {
            this.page = 0;
          }
          this.dataSource = new MatTableDataSource(this.testsuites);
          this.dataSource.sort = this.sort;
          this.dataSource.connect().subscribe((d) => (this.renderedData = d));
          this.dataSource.connect().subscribe((d) => {
            if (this.renderedData) {
              this.testsuitesNames = null;
              this.testsuitesNames = this.renderedData.map(function (item) {
                return item.name;
              });
              localStorage.setItem("testsuitesNames", this.testsuitesNames);
            }
          });

          this.currentCategory = this.category;
          if (
            this.category != "All" &&
            this.length == 0 &&
            this.searchCounter == 0
          ) {
            this.searchCounter++;
            this.category = "All";
            this.searchByCategory();
          }
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
  }

  getSummary() {
    this.handler.activateLoader();
    this.runService
      .getSummary(this.id, this.page, this.pageSize, this.isSort)
      .subscribe(
        (results) => {
          if (this.handler.handle(results)) {
            return;
          }
          this.testsuites = results["data"];
          this.length = results["totalElements"];
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
  }

  getCounts() {
    this.handler.activateLoader();
    this.PlaybookChart3 = null;
    this.ccArray2 = [];
    this.testSuiteService
      .getCategoryCountsByStatusPending(this.id, this.reviewStatus)
      .subscribe((results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.categoryCount = results["data"];
        this.categoryCount.sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );

        this.categoryCountType = this.categoryCount
          .map((x) =>
            x.count != 0 || x.groupby != null
              ? {
                  count: x.count,
                  groupBy: x.groupBy,
                  inactive: x.inactive,
                  label: x.label,
                  type: x.type,
                  owaspRank: "OWASP " + x.owaspRank,
                }
              : ""
          )
          .filter((v) => v);

        //sorting categories base on OWASP & GROUP BY
        this.categoryCountType.sort(function (a: any, b: any) {
          if (a["owaspRank"].includes("null")) {
            a["owaspRank"] = "";
          }
          if (b["owaspRank"].includes("null")) {
            b["owaspRank"] = "";
          }
          var aaOwaspRank = a["owaspRank"].toString().split(/(\d+)/);
          var bbOwaspRank = b["owaspRank"].toString().split(/(\d+)/);
          var aaGroupBy = a["groupBy"].toString().split(/(\d+)/);
          var bbGroupBy = b["groupBy"].toString().split(/(\d+)/);

          for (
            var x = 0;
            x < Math.max(aaOwaspRank.length, bbOwaspRank.length);
            x++
          ) {
            if (aaOwaspRank[x] != bbOwaspRank[x]) {
              var cmp1 = isNaN(parseInt(aaOwaspRank[x], 10))
                ? aaOwaspRank[x]
                : parseInt(aaOwaspRank[x], 10);
              var cmp2 = isNaN(parseInt(bbOwaspRank[x], 10))
                ? bbOwaspRank[x]
                : parseInt(bbOwaspRank[x], 10);
              if (cmp1 == undefined || cmp2 == undefined)
                return aaOwaspRank.length - bbOwaspRank.length;
              else return cmp1 < cmp2 ? -1 : 1;
            }
          }

          for (
            var x = 0;
            x < Math.max(aaGroupBy.length, bbGroupBy.length);
            x++
          ) {
            if (aaGroupBy[x] != bbGroupBy[x]) {
              var cmp1name = isNaN(parseInt(aaGroupBy[x], 10))
                ? aaGroupBy[x]
                : parseInt(aaGroupBy[x], 10);
              var cmp2name = isNaN(parseInt(bbGroupBy[x], 10))
                ? bbGroupBy[x]
                : parseInt(bbGroupBy[x], 10);
              if (cmp1name == undefined || cmp2name == undefined)
                return aaGroupBy.length - bbGroupBy.length;
              else return cmp1name < cmp2name ? -1 : 1;
            }
          }
          return 0;
        });

        // null value to the bottom in categorycounttype
        if (this.categoryCountType) {
          const nullList = this.categoryCountType.filter(
            (item) => item["owaspRank"] === ""
          );
          const notNullList = this.categoryCountType.filter(
            (item) => item["owaspRank"] !== ""
          );
          this.categoryCountType = [];
          this.categoryCountType = notNullList.concat(nullList);
        }

        const tempArray = this.categoryCountType
          .map((x) => ({
            value: x.count,
            name: x.groupBy,
          }))
          .filter((v) => v);

        tempArray.sort((a, b) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        );

        var ctObj = {
          value: "",
          name: "",
        };
        for (let ct of this.categoryCountType) {
          var catName = ct.groupBy + "(" + ct.count + ")";
          ctObj = {
            value: ct.count,
            name: catName,
          };
          this.ccArray2.push(ctObj);
        }

        // Pie chart
        this.PlaybookChart3 = {
          title: {
            text: "Coverage Breakdown",
            x: "left",
            textStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: "item",
            backgroundColor: "white",
            formatter: "{b}",
            textStyle: {
              fontSize: "11",
              color: "grey",
            },
          },

          calculable: true,
          series: [
            {
              type: "pie",
              radius: "75%",
              center: ["50%", "53%"],

              data: this.ccArray2,
              label: {
                normal: {
                  show: true,
                  position: "outside",
                  textStyle: {
                    fontSize: "10",
                  },
                },
                emphasis: {
                  show: true,
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            },
          ],
        };
      });
  }

  categoryObj = {
    count: "",
    groupBy: "",
    label: "",
  };
  isNull: boolean = false;

  getCategoryCountsByStatus() {
    this.testSuiteService
      .getCategoryCountsByStatus(this.id)
      .subscribe((results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.categoryContByStatus = results["data"];
        if (this.categoryContByStatus) {
          var obj2 = {
            groupBy: "All",
            count: this.categoryContByStatus
              .map((i) => i.count)
              .reduce((ab, bc) => ab + bc, 0),
            label: "All Playbooks",
          };
          this.addSameObj();
          for (let cs of this.categoryContByStatus) {
            if (cs.groupBy == null) cs.groupBy = "NEW";
            if (cs.groupBy == "PENDING") {
              cs.groupBy = "ERROR";
              cs.label = "Error Playbooks";
            }
            if (cs.groupBy == "NEW") {
              cs.label = "New Playbooks";
            }
            if (cs.groupBy == "AUTO") {
              cs.label = "Working Playbooks";
            }
          }

          //check if there are any pending/error playbooks obj
          // console.log(this.categoryContByStatus.some(c => c.groupBy === 'ERROR'))
          if (!this.categoryContByStatus.some((c) => c.groupBy === "ERROR")) {
            var obj = {
              count: 0,
              groupBy: "ERROR",
              inactive: null,
              label: "Error Playbooks",
              owaspRank: null,
              percentage: 0,
              type: null,
            };
            this.categoryContByStatus.push(obj);
          }

          // this.categoryContByStatus = this.categoryContByStatus.filter(x => x.groupBy !=null, this.isNull=true);
          if (!this.categoryContByStatus.includes(obj2))
            this.categoryContByStatus.unshift(obj2);
        }
        setTimeout((s) => {
          this.categoriesGroup = [
            {
              name: "Proofread",
              status: this.categoryContByStatus,
            },
            {
              name: "Managed",
              status: this.categoryByManaged,
            },
            {
              name: "Category",
              status: this.categoryCountType,
            },
          ];
        }, 1000);
      });
  }

  addSameObj() {
    var obj = this.categoryContByStatus.find((x) => {
      return x.groupBy === null;
    });
    this.categoryContByStatus.map((o) => {
      if (o.groupBy === "NEW" && obj) {
        o.count = o.count + obj.count;
      }
    });
    this.categoryContByStatus = this.categoryContByStatus.filter(
      (x) => x.groupBy !== null
    );
  }

  showTSDialog(testSuiteID, testSuiteName, autoGenerated) {
    const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
      width: "1400px",
      maxWidth: "90vw",
      data: [this.id, testSuiteID, testSuiteName, autoGenerated],
    });

    dialogRef.componentInstance.emitData.subscribe((result) => {
      if (result[0] == this.id) {
        this.refresh();
      }
    });
  }

  newScript() {
    const dialogRef = this.dialog.open(TestsuitNewDialogComponent, {
      width: "1400px",
      maxWidth: "90vw",
      data: [this.id],
    });

    dialogRef.componentInstance.emitData.subscribe((result) => {
      if (result[0] == this.id) {
        this.refresh();
      }
    });
  }

  refresh() {
    // this.taskStatus = "In_progress";
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      // this.loadProject(this.id);
      // this.list(this.id);
      this.searchByCategory();
      this.getCounts();
      this.getCategoryCountsByStatus();
      this.playbookCountSnapshot(this.id);
    });
  }

  openAutoCodeSyncDialog() {
    this.autoCodeSyncObj = [];
    this.autoCodeSyncDeleteObj = [];
    this.categoryCountType = [];
    for (let res of this.categoryCount) {
      if (
        res.type != null &&
        res.type != "customize" &&
        res.inactive == false
      ) {
        this.autoCodeSyncObj.push({
          displayHeaderLabel: res.label,
          type: res.type,
        });
      }

      if (res.type != null && res.type != "customize" && res.count > 0) {
        this.autoCodeSyncDeleteObj.push({
          displayHeaderLabel: res.label,
          type: res.type,
        });
      }
    }

    const dialogRef = this.dialog.open(AutoSyncComponent, {
      width: "700px",
      data: [this.project, this.autoCodeSyncObj, this.autoCodeSyncDeleteObj],
    });

    dialogRef.componentInstance.emitData.subscribe((response) => {
      this.taskStatus = "In_progress";
      this.searchCounter = 0;
      this.getPlaybookCreationLoader();
      // if(response == 'create'){
      // }
    });
    // tslint:disable-next-line: no-empty
    // dialogRef.afterClosed().subscribe(result => {
    //   this.taskStatus="In-progress";
    //     setTimeout(s=> {
    //         this.getPlaybookCreationLoader();
    //     },4000);
    // });
  }

  playbookCountSnapshot(id) {
    this.PlaybookChart = null;
    this.snapshotCount = [];
    this.snapShotDate = [];
    this.tsCount = [];

    this.handler.activateLoader;
    this.testSuiteService.getPlaybookCountSnapshot(id).subscribe((results) => {
      this.snapshotCount = results["data"];
      for (const count of this.snapshotCount) {
        if (count.snapshotDate != null) {
          count.snapshotDate = this.datePipe.transform(
            count.snapshotDate,
            "mediumDate"
          );
          this.snapShotDate.push(count.snapshotDate);
        }
        if (count.tsCount != null) {
          this.tsCount.push(count.tsCount);
        }
      }

      //below if condition is required to show empty graph lines
      if (this.tsCount.length == 0) {
        this.tsCount.push(" ");
      }
      var week = this.snapShotDate.length;
      for (var i = 0; i < 8; i++) {
        if (i >= week) {
          this.snapShotDate.push("");
        }
      }

      // E Charts
      this.PlaybookChart = {
        title: {
          text: "Playbooks Trend",
          textStyle: {
            fontSize: 14,
          },
        },
        legend: {
          show: true,
          x: "center",
          y: "top",
          textStyle: {
            color: this.config.textColor,
          },
          data: ["Trend", "Playbooks"],
        },
        grid: {
          x: 40,
          y: 60,
          x2: 40,
          y2: 30,
          borderWidth: 0,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: this.config.gray,
            },
          },
          backgroundColor: "white",
          textStyle: {
            fontSize: "11",
            color: "grey",
          },
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                color: this.config.textColor,
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: this.config.splitLineColor,
              },
            },
            data: this.snapShotDate,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                color: this.config.textColor,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: this.config.splitLineColor,
              },
            },
          },
        ],
        // toolbox: {
        //   show: true,
        //   feature: {
        //     saveAsImage: { show: true, title: "save" }
        //   }
        // },
        series: [
          {
            name: "Trend",
            type: "line",
            clickable: false,
            lineStyle: {
              normal: {
                color: this.config.gray,
              },
            },
            areaStyle: {
              normal: {
                color: this.config.lightBlue,
              },
              emphasis: {},
            },
            data: this.tsCount,
            markPoint: {
              data: [
                { type: "max", name: "" },
                { type: "min", name: "" },
              ],
            },
            legendHoverLink: false,
            z: 1,
          },
          {
            name: "Playbooks",
            type: "bar",
            stack: "traffic",
            clickable: false,
            itemStyle: {
              normal: {
                color: this.config.primary, // '#8BC34A', // Light Green 500
                barBorderRadius: 0,
                label: { show: true },
              },
              emphasis: {
                // color: this.config.success
              },
            },
            barCategoryGap: "60%",
            data: this.tsCount,
            markPoint: {
              data: [
                { type: "max", name: "" },
                { type: "min", name: "" },
              ],
            },
            barMaxWidth: 50,
            legendHoverLink: false,
            z: 2,
          },
        ],
      };
      //   E Charts
    });
    this.handler.hideLoader;
  }

  openSample() {
    const dialogRef = this.dialog.open(PlaybookSampleDialogComponent, {
      width: "1200px",
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  //get category on click
  getClickCategory(el: HTMLElement) {
    // if (localStorage.getItem("selCategory") != this.category) {
    const selCategory = localStorage.getItem("selCategory");
    this.category = selCategory.replace(/ *\([^)]*\) */g, "");
    if (this.category != "" || this.category != undefined)
      this.searchByCategory();
    el.scrollIntoView({ behavior: "smooth" });
    // }
  }

  //get Column data on click
  getBarColumn() {
    var myChart = echarts.init(document.getElementById("barColumn"));
    // if (myChart._dom)
    //   console.log(myChart._dom.textContent);
  }

  isPrpegated(obj) {
    var dialogResp = localStorage.getItem("Propegation") === "true";
    if (dialogResp) {
      localStorage.removeItem("Propegation");
      return "In_progress";
    }
    return obj;
  }

  getPlaybookCreationStatus() {
    this.tasksService.getEventStatus(this.id, "Sync").subscribe((results) => {
      if (this.handler.handle(results)) {
        return;
      }
      this.tasksRes = results["data"];
      if (this.tasksRes)
        this.taskStatus = this.isPrpegated(this.tasksRes["status"]);
      if (this._clockSubscription && this.taskStatus != "In_progress") {
        this._clockSubscription.unsubscribe();
      }
    });
  }

  playbookToggle() {
    if (this.playbookStatusFlag) this.category = "All";
    else this.category = "ERROR";
    this.searchByCategory();
    // this.getCounts();
  }

  changeSort(obj, event) {
    event.stopPropagation();
    this.sortString = obj;
    //  if (event.currentTarget.ariaSort == "ascending") this.sortType = "ASC"; else this.sortType = "DESC";
    if (this.sortType == "ASC") this.sortType = "DESC";
    else this.sortType = "ASC";
    this.searchByCategory();
  }
}
