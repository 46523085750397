import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { Handler } from "../components/dialogs/handler/handler";
import { OrgService } from "../services/org.service";
import { SnackbarService } from "../services/snackbar.service";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-teams-list-",
  templateUrl: "./teams-list.component.html",
  styleUrls: ["./teams-list.component.scss"],
  providers: [SnackbarService, OrgService],
})
export class TeamsListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ["Name", "Description", "Users"];
  dataSource = null;
  @Output() emitData = new EventEmitter<string[]>();

  constructor(
    private orgService: OrgService,
    private handler: Handler,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe((keyword) => {
        this.searchSubject.next(this.keyword);
        this.keyword = keyword;
        this.searchOrgs();
      });

    this.teamList();
  }

  teamListData;
  page = 0;
  pageSize = 10;
  length = 0;
  keyword: string = "";
  sort1 = "createdDate";
  sortType = "DESC";
  users = [];
  selectedUserID = [];

  lastSearchEvent: any;
  lastEvent: any;
  userName: any;
  change(evt) {
    // this.lastEvent = evt;
    this.page = evt["pageIndex"];
    this.pageSize = evt.pageSize;
    this.teamList();

    // if (this.lastSearchEvent != null) this.search(this.lastSearchEvent);
    // else this.teamList();
  }
  teamList() {
    this.handler.activateLoader();
    this.orgService
      .getTeam(this.page, this.pageSize, this.sort1, this.sortType)
      .subscribe(
        (result) => {
          this.handler.hideLoader();
          if (this.handler.handle(result)) {
            return;
          }
          this.teamListData = result["data"].content;
          this.length = result["data"].totalElements;
          this.dataSource = new MatTableDataSource(this.teamListData);
          this.dataSource.sort = this.sort;
          // this.page = result['data'].teamListData.pageable.pageNumber - 1; // Make sure to use the correct property from the API response
          this.pageSize = result["data"].size;
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
    // this.searchOrgs();
  }
  searchList;
  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;
  onSearchInput(event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchSubject.next(inputElement.value);
  }

  clearSearch(){
    this.keyword = '';
    this.onSearchInput({target:{value:''}} as any);
  }

  resultOfTeams : boolean = true;
  searchOrgs() {
    this.resultOfTeams = false;
    this.page = 0;
    // if (this.keyword.length >= 3) {
    this.handler.activateLoader();
    this.orgService
      .getTeamSearch(
        this.keyword,
        this.page,
        this.pageSize,
        this.sort1,
        this.sortType
      )
      .subscribe(
        (results) => {
          this.handler.hideLoader();
          if (this.handler.handle(results)) {
            return;
          }
          this.searchList = results["data"].content;
          this.length = results["data"].totalElements;
          this.dataSource = new MatTableDataSource(this.searchList);
          this.dataSource.sort = this.sort;
          this.resultOfTeams = true;
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
    // }
  }
  changeSort(obj, event) {
    event.stopPropagation();
    this.sort1 = obj;
    if (this.sortType == "ASC") this.sortType = "DESC";
    else this.sortType = "ASC";
    this.teamList();
  }

  getTooltipContent(element) {
    return element.map((item) => item.name).join(", ");
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
}
