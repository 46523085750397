import {
  CATEGORIES,
  CATEGORIES_TYPE_COUNT,
  selectPageSize,
  recentSearchCat,
  searchCatResult,
  GlobalProjectDetails,
} from "./../../../shared/shared.data";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { TestSuiteService } from "../../../services/test-suite.service";
import { Project } from "../../../models/project.model";
import { AutoSUggest } from "../../../models/project.model";
import { ProjectService } from "../../../services/project.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Handler } from "../../dialogs/handler/handler";
import { MsgDialogComponent } from "../../dialogs/msg-dialog/msg-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { concat, Subject, Subscription } from "rxjs";
import { RunService } from "../../../services/run.service";
import { JobsService } from "../../../services/jobs.service";
import {
  ELASTICSEARCHVALIDATOR,
  GlobalSelectedEnv,
} from "../../../shared/shared.data";
import { SnackbarService } from "../../../services/snackbar.service";
import { DatePipe } from "@angular/common";
import { CHARTCONFIG } from "./../../../charts/charts.config";
import * as XLSX from "xlsx";
import * as moment from "moment";
import { AdvRunComponent } from "../../dialogs/adv-run/adv-run.component";
import { TasksService } from "../../../services/tasks.service";
import { timer } from "rxjs";
import { Tasks } from "../../../models/tasks.model";
import { ArchivedCategoriesDialogComponent } from "../../dialogs/archived-categories-dialog/archived-categories-dialog.component";
import { Run } from "../../../models/run.model";
import { UpgradeComponent } from "../../dialogs/upgrade/upgrade.component";
import { VulnerabilitySyncDialogComponent } from "../../dialogs/vulnerability-sync-dialog/vulnerability-sync-dialog.component";
import downloadCsv from "download-csv";
import { AlertDialogComponent } from "../../dialogs/alert-dialog/alert-dialog.component";
import { all } from "core-js/fn/promise";
import { MixpanelService } from "../../../services/mixpanel.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { event } from "jquery";

@Component({
  selector: "app-project-recommendations",
  templateUrl: "./project-recommendations.component.html",
  styleUrls: ["./project-recommendations.component.scss"],
  providers: [
    ProjectService,
    TestSuiteService,
    RunService,
    JobsService,
    SnackbarService,
    DatePipe,
    TasksService,
  ],
})
export class ProjectRecommendationsComponent implements OnInit {
  id; // project id
  vulnerList: { legendData: any[]; seriesData: any[] };
  project: Project = new Project();
  actVulnerGraph: any = null;
  vulnerbySeverityGraph: any = null;
  autoSuggestDS = null;
  autoSuggest: AutoSUggest[];
  length = 0;
  page = 0;
  pageSize = 10;
  pageIndex = 0;
  selectedCategories: string;
  keyword: string = "";
  category: string = "All";
  currentCategory;
  archived_recommendations = "-";
  totalEstimate = 0;
  toggleValue = "active";
  activeCount: any = 0;
  archivedCount: any = 0;
  bountyValue: any;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  response;
  wireLogDetails: any = [];
  renderedData;
  passed;
  failed;
  job;
  jobId;
  searchStringValidator = ELASTICSEARCHVALIDATOR;
  vulGraph = [];
  categoriesCount = [];
  ExcelData = [];
  excel = [];
  spDate = [];
  spCount = [];
  config = CHARTCONFIG;
  categoryCountType = [];
  cat = CATEGORIES_TYPE_COUNT;
  samePage: boolean = true;
  samePageCat: boolean = true;
  currentSuiteName: string;
  globPageSize = selectPageSize;
  globRecentCat = recentSearchCat;
  globalSelectedEnv = GlobalSelectedEnv;
  jobs;
  activeCategoriesCounts = [];
  activeVulnerList = [];
  globCatFilter = searchCatResult;
  categoryCount = [];
  activeCategories = [];
  displayedColumns: string[] = [
    "path",
    "rank",
    "category",
    // 'generatorType',
    "cvssScore",
    "severity",
    "createdDate",
    "actions",
  ];
  categoryCountByStatus: any = [];
  totalProofread: number = 0;
  autoValue = 0;
  manualValue = 0;
  CriticalValue = "";
  MajorValue = "";
  MinorValue = "";
  TrivialValue = "";
  HighValue = "";
  MediumValue = "";
  LowValue = "";
  nextFire: any = null;
  btnScanDisable: boolean = false;
  vulnerbySeverityRes: any;
  // displayedColumns: string[] = [
  //   "rank",
  //   "type",
  //     "cvss",
  //   "category",
  //   "endPoint",
  //   // "issueId",
  //   // "resourceName",
  //   // "status",
  //   "severity",
  //   "falsePositive",
  //   "createdDate",
  //   "actions"
  // ];
  totalEndpoints = 0;
  totalPlaybooks: any = 0;
  assistant1;
  completionPercentage = 0;
  pendingPercentage = 0;
  totalCompleted = 0;
  totalPending = 0;
  totActGenerators = 0;
  totalAssestCategories = 0;
  totalActiveCategories: any = 0;
  assest: number = 0;
  snapshotCount: any = null;
  selectedSeverity: any = "all";
  freeAcc: boolean = true;
  severitiesList = [
    { label: "All", value: "all" },
    { label: "Critical", value: "Critical" },
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];
  // runsCount: any = null;
  @ViewChild("openCloseTable") openCloseTable: {
    nativeElement: { scrollIntoView: (arg0: { behavior: string }) => void };
  };
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pixelWidth: any;
  invalidTestCounts: any = 0;
  taskPlaybookResult: Tasks = new Tasks();
  taskRunResult: Tasks = new Tasks();
  tempRunResult: Tasks = new Tasks();
  runs: any = [];
  run: Run = new Run();
  report: any;
  runId = "";
  masterJob;
  today: number = Date.now();
  private _clockSubscription: Subscription;
  private _clockSubscription2: Subscription;
  sortString: string = "severity";
  sortType: string = "ASC";
  initCall: boolean = true;
  constructor(
    private myChangeDetectorRef: ChangeDetectorRef,
    private elems: ElementRef,
    private testSuiteService: TestSuiteService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private jobsService: JobsService,
    private route: ActivatedRoute,
    private router: Router,
    private handler: Handler,
    private runService: RunService,
    private snackbarService: SnackbarService,
    private datePipe: DatePipe,
    private tasksService: TasksService,
    private mixpanelService: MixpanelService // public media: ObservableMedia
  ) {
    // this.media.subscribe((change: MediaChange) => {
    // });
  }
  testsuitesNames: any = [];
  vulnerabilitiesbySeverity: any = [];
  VulChart: any;
  catSearchBackData = {
    category: "",
    toggleValue: "",
    keyword: "",
    page: 0,
    pageSize: 0,
    activeCount: 0,
    severity: "",
  };
  mobile: boolean = false;
  loggedInUser;
  userRole;
  any;
  projectStats;
  intervalId: any;

  ngOnInit() {
    if (this.globCatFilter.catResult && this.globCatFilter.catResult != null) {
      if (Object.keys(this.globCatFilter.catResult).length !== 0) {
        this.selectedSeverity = this.globCatFilter.catResult.severity;
        this.toggleValue = this.globCatFilter.catResult.toggleValue;
      }
    }
    window.scroll(0, 0);
    this.pixelWidth = window.screen.width;
    if (this.pixelWidth <= 1000) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.handler.activateLoader();
    this.route.params.subscribe(
      (params) => {
        this.id = params["id"];
        this.getEnvs();
        this.getProjectStats();
        setTimeout((s) => {
          this.getPlaybookCreationStatus();
          if (this.taskPlaybookResult["status"] == "In_progress") {
            const timer3 = timer(10000, 10000);
            this._clockSubscription2 = timer3.subscribe((t) => {
              this.checkAndUpdateReviewStatuswise();
            });
          }
        }, 5000);
        // this.intervalId = setInterval(() => {
        //   this.getPlaybookCreationStatus();

        //   if (this.taskPlaybookResult["status"] == "In_progress") {
        //     this.checkAndUpdateReviewStatuswise();
        //   }
        // }, 5000);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
    // this.vulnerabilityGraph(this.id);
    this.loggedInUser = localStorage.getItem("loggedInUser");
    this.userRole = localStorage.getItem("userRole");

    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe((keyword) => {
        // this.keyword = keyword;
        this.searchSubject.next(this.keyword);
        this.search(event);
      });
  }

  onSearchInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchSubject.next(inputElement.value);
  }

  clearSearch() {
    this.keyword = "";
    this.onSearchInput({ target: { value: "" } } as any);
  }

  checkAndUpdateReviewStatuswise() {
    this.testSuiteService
      .getCategoryCountsByStatus(this.id)
      .subscribe((results) => {
        if (this.handler.handle(results)) {
          return;
        }
        const categoryCountByStatus = results["data"];
        let newTotalPlaybooks = 0;

        if (categoryCountByStatus) {
          for (const cs of categoryCountByStatus) {
            if (cs.count) {
              newTotalPlaybooks += cs.count;
            }
          }
        }

        // Check if the totalPlaybooks value is different from its previous value
        if (newTotalPlaybooks !== this.previousTotalPlaybooks) {
          this.previousTotalPlaybooks = newTotalPlaybooks;
          this.getReviewStatuswise(categoryCountByStatus);
        }
      });
  }

  previousTotalPlaybooks = null;
  changeSort(obj, event) {
    event.stopPropagation();
    this.sortString = obj;
    //  if (event.currentTarget.ariaSort == "ascending") this.sortType = "ASC"; else this.sortType = "DESC";
    if (this.sortType == "ASC") this.sortType = "DESC";
    else this.sortType = "ASC";
    if (this.lastEvent != null) this.change(this.lastEvent);
    else if (this.category != "" || this.selectedSeverity != "")
      this.searchByCategory();
    else this.loadSuggestions(this.id, this.toggleValue);
  }

  getProjectStats() {
    this.handler.activateLoader();
    this.projectService.getProjectStats(this.id).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.projectStats = results["data"];
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  globalProjectDetails = GlobalProjectDetails;
  loadProject(id: string) {
    this.handler.activateLoader();
    this.projectService.getById(id).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.project = results["data"];
        if (this.project) this.orgId = this.project["org"]["id"];
        localStorage.setItem("projectName", this.project.name);
        localStorage.setItem("projectId", this.project.id);
        this.globalProjectDetails.name = this.project.name;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
    // this.get("count-archived-auto-suggestions", "archived");
  }

  btnVulnerability(pageName) {
    this.globPageSize.pageSize_Global = "";
    this.globCatFilter.catResult = null;
    // if (pageName == 'Coverage')
    //     this.router.navigate(['/app/projects/', this.id, 'configuration']);
    // if (pageName == 'Playbooks')
    //     this.router.navigate(['/app/projects/', this.id, 'playbooks']);
    // if (pageName == 'Scans')
    //     this.router.navigate(['/app/projects/', this.id, 'profiles']);
    if (pageName == "Vulnerabilities")
      this.router.navigate(["/app/projects/", this.id, "dashboard"]);
    if (pageName == "Reporting")
      this.router.navigate(["/app/reports/projects/", this.id, "reporting"]);
    if (pageName == "Settings")
      this.router.navigate(["/app/projects/", this.id, "details"]);
    if (pageName == "Environments")
      this.router.navigate([
        "/app/config-environments/projects/",
        this.id,
        "environmentList",
      ]);
  }

  loadSuggestions(id: string, toggleValue: string) {
    if (this.globPageSize.pageSize_Global) {
      this.page = this.globPageSize.pageSize_Global["pageIndex"];
      this.pageSize = this.globPageSize.pageSize_Global["pageSize"];
    } else {
      this.page = 0;
      this.pageIndex = 0;
    }
    this.globRecentCat.recent_global = "";
    this.globRecentCat.recentSrc_global = "";
    if (this.selectedSeverity == undefined) {
      this.selectedSeverity = "all";
    }
    this.handler.activateLoader();
    this.projectService
      .getAutoSuggestionsBackendSort(
        id,
        this.initCall ? "" : toggleValue,
        this.selectedEnv,
        this.selectedSeverity,
        this.page,
        this.pageSize,
        this.sortString,
        this.sortType,
        this.keyword
      )
      .subscribe(
        (results) => {
          this.handler.hideLoader();
          this.initCall = false;
          if (this.handler.handle(results)) {
            return;
          }
          //  results = this.generateData(results);
          this.autoSuggest = results["data"];
          this.autoSuggestDS = new MatTableDataSource(this.autoSuggest);
          this.autoSuggestDS.sort = this.sort;
          this.autoSuggestDS
            .connect()
            .subscribe((d) => (this.renderedData = d));
          this.autoSuggestDS.connect().subscribe((d) => {
            if (this.renderedData) {
              this.testsuitesNames = null;
              this.testsuitesNames = this.renderedData.map(function (item) {
                return {
                  runId: item.runId,
                  testsuitesName: item.testSuiteName,
                };
              });
              // localStorage.setItem('testsuitesNames', this.testsuitesNames);
            }
          });
          this.length = results["totalElements"];
          for (const entry of this.autoSuggest) {
            if (entry.category == "RBAC") {
              entry.rbacAction = this.getRbacAction(entry.testSuiteName);
            }
          }
          if (toggleValue == "active") {
            this.activeCount = results["totalElements"];
          } else {
            this.get("count-archived-auto-suggestions", "archived");
          }
        },
        (error) => {
          if (this._clockSubscription != null) {
            console.log("Inside error - unsubscribe");
            this._clockSubscription.unsubscribe();
          }
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
  }

  loadCategoriesCount(id: string, toggleValue: string) {
    this.categoryCountType = [];
    this.categoryCount = [];
    this.handler.activateLoader();
    this.projectService
      .getRecommendationCategoriesCount(id, toggleValue, this.selectedEnv)
      .subscribe((results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        // this.categoryCountType = results["data"];
        let tmpCategoryCountType = [];
        tmpCategoryCountType = results["data"];
        this.categoryCount = tmpCategoryCountType;
        if (this.categoryCount && this.categoryCount.length > 0) {
          // sort by String owaspRank
          tmpCategoryCountType.sort((a, b) => {
            return a.owaspRank == null
              ? 1
              : a.owaspRank < b.owaspRank
              ? -1
              : a.owaspRank > b.owaspRank
              ? 1
              : a.owaspRank == b.owaspRank
              ? a.label < b.label
                ? -1
                : a.label > b.label
                ? 1
                : 0
              : 0;
          });
          // sort by Int owaspRank
          tmpCategoryCountType.sort((a, b) => {
            return parseInt(a.owaspRank) < parseInt(b.owaspRank)
              ? -1
              : parseInt(a.owaspRank) > parseInt(b.owaspRank)
              ? 1
              : parseInt(a.owaspRank) == parseInt(b.owaspRank)
              ? a.label < b.label
                ? -1
                : a.label > b.label
                ? 1
                : 0
              : 0;
          });
          tmpCategoryCountType.map((x) => {
            if (!x.label) {
              x.label = x.groupBy;
            }
            const obj = {
              count: x.count,
              groupBy: x.groupBy,
              label: x.label,
              type: x.type,
              estimate: x.estimate,
              owaspRank: x.owaspRank
                ? x.owaspRank.includes("Top")
                  ? x.owaspRank
                  : "OWASP " + x.owaspRank
                : "",
            };
            this.categoryCountType.push(obj);
          });
          this.totalEstimate = this.categoryCountType
            .map((i) => i.estimate)
            .reduce((a, b) => a + b, 0);
          const obj1 = {
            groupBy: "All",
            count: this.categoryCountType
              .map((i) => i.count)
              .reduce((a, b) => a + b, 0),
            label: "All",
            estimate: this.totalEstimate,
          };
          if (!this.categoryCountType.includes(obj1))
            this.categoryCountType.unshift(obj1);
          this.getActiveVulnerability();
        }
      });
  }

  lastEvent: any;
  change(evt) {
    this.lastEvent = evt;
    this.globPageSize.pageSize_Global = evt;
    this.page = evt["pageIndex"];
    this.pageIndex = this.page;
    if (this.keyword == "" && this.category == "All") {
      this.pageSize = evt.pageSize;
      return this.loadSuggestions(this.id, this.toggleValue);
    } else if (this.keyword != "" && this.category != "All") {
      this.searchByCategory();
    } else {
      this.pageSize = evt.pageSize;
      this.search(event);
    }
  }
  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;
  resultOfVul: boolean = true;

  search(event) {
    if (
      this.keyword.length == 0 ||
      (this.keyword.length >= 0 && this.categoryCountType.length > 1)
    ) {
      // this.keyword = this.searchStringValidator.checkSearchString(this.keyword);
      const key = event.keyCode;
      if (this.searchStringValidator.isValidSearchKeyCode(key)) {
        if (this.keyword != this.globRecentCat.recentSrc_global) {
          this.page = 0;
          this.myChangeDetectorRef.detectChanges();
          this.pageIndex = 0;
        }
        if (this.keyword == "" && this.category == "All") {
          return this.loadSuggestions(this.id, this.toggleValue);
        }
        if (this.globCatFilter.catResult) {
          this.globCatFilter.catResult = null;
        }
        let category_ = "";
        if (this.category == "All") {
          category_ = "";
        } else {
          category_ = this.category;
        }
        // this.catSearchBackData = {
        //   id: this.id,
        //   category: category_,
        //   toggleValue: this.toggleValue,
        //   keyword: this.keyword,
        //   page: this.page,
        //   pageSize: this.pageSize
        // }
        if (this.keyword.length >= 0) {
          this.resultOfVul = false;
          this.handler.activateLoader();
          this.projectService
            .searchAutoSuggestions(
              this.id,
              category_,
              this.toggleValue,
              this.keyword,
              this.page,
              this.pageSize,
              this.selectedEnv,
              this.selectedSeverity,
              this.sortString,
              this.sortType
            )
            .subscribe(
              (results) => {
                this.handler.hideLoader();
                this.resultOfVul = true;
                if (this.handler.handle(results)) {
                  return;
                }
                //  results = this.generateData(results);
                this.autoSuggest = results["data"];
                this.autoSuggestDS = new MatTableDataSource(this.autoSuggest);
                this.autoSuggestDS.sort = this.sort;
                // this.globFilter.Result = this.autoSuggestDS
                this.autoSuggestDS
                  .connect()
                  .subscribe((d) => (this.renderedData = d));
                this.autoSuggestDS.connect().subscribe((d) => {
                  if (this.renderedData) {
                    this.testsuitesNames = null;
                    this.testsuitesNames = this.renderedData.map(function (
                      item
                    ) {
                      return {
                        runId: item.runId,
                        testsuitesName: item.testSuiteName,
                      };
                    });
                    // localStorage.setItem('testsuitesNames', this.testsuitesNames);
                  }
                });
                this.length = results["totalElements"];
                this.globRecentCat.recentSrc_global = this.keyword;
                for (const entry of this.autoSuggest) {
                  if (entry.category == "RBAC") {
                    entry.rbacAction = this.getRbacAction(entry.testSuiteName);
                  }
                }
                this.length = results["totalElements"];
              },
              (error) => {
                this.handler.hideLoader();
                this.handler.error(error);
              }
            );
        } else {
          this.searchByCategory();
        }
      }
    }
  }

  searchByCategory() {
    // if(this.categoryCountType.length>1){
    if (this.globRecentCat.recent_global == "") {
      this.globRecentCat.recent_global = "All";
    }
    if (this.category == "") {
      this.category = "All";
    }
    if (!this.category.match(this.globRecentCat.recent_global)) {
      this.page = 0;
      this.myChangeDetectorRef.detectChanges();
      this.pageIndex = 0;
    }
    if (this.globPageSize.pageSize_Global) {
      this.globPageSize.pageSize_Global = null;
    }
    if (this.keyword == "" && this.category == "All") {
      return this.loadSuggestions(this.id, this.toggleValue);
    }
    let category_ = "";
    if (this.category == "All") {
      category_ = "";
    } else {
      category_ = this.category;
    }
    this.handler.activateLoader();
    this.projectService
      .searchAutoSuggestions(
        this.id,
        category_,
        this.toggleValue,
        this.keyword,
        this.page,
        this.pageSize,
        this.selectedEnv,
        this.selectedSeverity,
        this.sortString,
        this.sortType
      )
      .subscribe(
        (results) => {
          this.handler.hideLoader();
          if (this.handler.handle(results)) {
            return;
          }
          // results = this.generateData(results);
          this.autoSuggest = results["data"];
          this.autoSuggestDS = new MatTableDataSource(this.autoSuggest);
          this.autoSuggestDS.sort = this.sort;
          // this.autoSuggestDS.paginator = this.paginator;
          this.autoSuggestDS
            .connect()
            .subscribe((d) => (this.renderedData = d));
          this.autoSuggestDS.connect().subscribe((d) => {
            if (this.renderedData) {
              this.testsuitesNames = null;
              this.testsuitesNames = this.renderedData.map(function (item) {
                return {
                  runId: item.runId,
                  testsuitesName: item.testSuiteName,
                };
              });
              // localStorage.setItem('testsuitesNames', this.testsuitesNames);
            }
          });
          this.length = results["totalElements"];
          for (const entry of this.autoSuggest) {
            if (entry.category == "RBAC") {
              entry.rbacAction = this.getRbacAction(entry.testSuiteName);
            }
          }
          this.length = results["totalElements"];
          if (!this.category.match(this.currentCategory) && this.page != 0) {
            this.page = 0;
            this.myChangeDetectorRef.detectChanges();
          }
          this.currentCategory = this.category;
          this.globRecentCat.recent_global = this.category;
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
    // }
  }

  get(stat: string, status: string) {
    this.projectService
      .getArchivedRecommendations(this.id, stat, status, this.selectedEnv)
      .subscribe(
        (results) => {
          if (results["errors"]) {
            // TODO - handle errors
            return;
          }
          this.archived_recommendations = results["data"];
          if (status == "active") {
            this.activeCount = results["data"];
          } else {
            this.archivedCount = results["data"];
          }
        },
        (error) => {
          console.log("Unable to fetch stat");
        }
      );
  }
  // showTSDialog(testSuiteId, testSuiteName) {
  //     const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
  //         width: "1200px",
  //         data: [this.id, testSuiteId, testSuiteName, ""]
  //     });
  // }
  getToggleValue(value: string) {
    this.toggleValue = value;
    if (value == "active") {
      this.get("count-archived-auto-suggestions", "archived");
    } else {
      this.get("count-archived-auto-suggestions", "active");
    }

    if (value == "archived" || value == "active") {
      this.selectedSeverity = "all";
      this.category = "All";
      this.keyword = "";
    }

    this.loadCategoriesCount(this.id, value);
    this.autoSuggestDS = null;
    this.categoryCountType = [];
    if (this.keyword == "" && this.category == "All") {
      return this.loadSuggestions(this.id, this.toggleValue);
    } else if (this.keyword != "" && this.category != "") {
      this.searchByCategory();
    } else {
      this.search(event);
    }
  }

  // archive By Categories
  archiveByCategories(toggleValue: string) {
    // console.log(this.toggleValue);
    const dialogRef = this.dialog.open(ArchivedCategoriesDialogComponent, {
      width: "600px",
      data: [this.id, toggleValue, this.categoryCountType, this.selectedEnv],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loadSuggestions(this.id, toggleValue);
      this.loadCategoriesCount(this.id, toggleValue);
      this.searchByCategory();
      this.get("count-archived-auto-suggestions", "archived");
      this.loadBountyValue(this.id);
    });
  }

  getRbacAction(testSuiteName: string) {
    const result = testSuiteName.substring(testSuiteName.length - 11);
    let action;
    if (result == "AllowedRbac") {
      action = "Disallow";
    }
    const result1 = testSuiteName.substring(testSuiteName.length - 14);
    if (result1 == "DisallowedRbac") {
      action = "Allow";
    }
    return action;
  }
  // howToFixDialog(category: string) {
  //     const dialogRef = this.dialog.open(HowToFixDialogComponent, {
  //         width: "900px",
  //         data: category
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //     });
  // }
  // openIssuesEnv(element: object) {
  //     const dialogRef = this.dialog.open(RecommendationsIssuesComponent, {
  //         width: "900px",
  //         data: element
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //     });
  // }
  // openLogs(issues: any[]) {
  //     if (issues.length > 0) {
  //         this.handler.activateLoader();
  //         this.projectService
  //             .getTestSuiteResponseByIssueId(this.id, issues[0].issueId)
  //             .subscribe(
  //                 results => {
  //                     this.handler.hideLoader();
  //                     if (this.handler.handle(results)) {
  //                         return;
  //                     }
  //                     this.response = results["data"];
  //                     this.passed = this.response.totalPassed;
  //                     this.failed = this.response.totalFailed;
  //                     this.showDialog(this.response.logs, this.response.testSuite);
  //                 },
  //                 error => {
  //                     this.handler.hideLoader();
  //                     this.handler.error(error);
  //                 }
  //             );
  //     } else {
  //         console.log("Issue is missing for this vulnerability");
  //     }
  // }
  // showDialog(msg, suitName) {
  //     let dialogRef = this.dialog.open(RecommendationsLogsComponent, {
  //         width: "1200px",
  //         data: [msg, suitName, this.id, this.passed, this.failed]
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //         localStorage.removeItem("suitename");
  //     });
  // }
  // openIssueTracker(jobId: string, issueId: string) {
  //     this.loadJob(jobId, issueId);
  // }
  loadJob(jobId: string, issueId: string) {
    this.handler.activateLoader();
    this.jobsService.getById(jobId).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.job = results["data"];
        if (this.job.issueTracker.accountType == "GitHub") {
          window.open(
            this.job.issueTracker.url + "/issues/" + issueId,
            "_blank"
          );
        } else if (this.job.issueTracker.accountType == "Jira") {
          window.open(this.job.issueTracker.url, "_blank");
        } else if (this.job.issueTracker.accountType == "Bugzilla") {
          window.open(
            this.job.issueTracker.url + "/show_bug.cgi?id=" + issueId,
            "_blank"
          );
        } else if (this.job.issueTracker.accountType == "FX_Issues") {
          // alert('This vulnerability is registerd on apisec Issue Tracker');
          let dialogRef = this.dialog.open(AlertDialogComponent, {
            width: "580px",
            height: "225px",
            data: [
              "",
              "This vulnerability is registerd on apisec Issue Tracker",
            ],
          });
        }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }
  // openCommentsDialog(autoSuggestion) {
  //     let dialogRef = this.dialog.open(RecommendationsCommentsComponent, {
  //         width: "800px",
  //         data: [autoSuggestion, this.id, this.toggleValue]
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //         if (result != null) {
  //             if (result[0]) {
  //                 if (
  //                     autoSuggestion.falsePositive == true &&
  //                     autoSuggestion.status != "SKIPPED"
  //                 ) {
  //                     this.deactivateAutoSuggestion(autoSuggestion);
  //                 } else if (autoSuggestion.status != "NEW" && result[1]) {
  //                     this.activateAutoSuggestion(autoSuggestion);
  //                 }
  //             }
  //         }
  //     });
  // }
  // openviewDetails(element) {
  //     const dialogRef = this.dialog.open(VulDetailsDialogComponent, {
  //         width: "900px",
  //         data: element
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //     });
  // }

  sortByProperty(property) {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  }

  removeSimilar(json) {
    const res = [];
    let prev = -1;
    // for(var k in obj) {
    for (let i = 0; i < json.length; i++) {
      const k = json[i];
      if (k.autoSuggestionCount != prev) {
        res.push(k);
        prev = k.autoSuggestionCount;
      }
    }
    return res;
  }

  loadBountyValue(id: string) {
    this.handler.activateLoader();
    this.projectService.getBountyValue(id, this.selectedEnv).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.bountyValue = results["data"];
        if (this.bountyValue == null) {
          this.bountyValue = 0;
        } else
          this.bountyValue = new Intl.NumberFormat().format(this.bountyValue);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }
  // Smooth scroll effect for tiles click
  scrollToElement(): void {
    this.openCloseTable.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  storedData() {
    if (this.globPageSize.pageSize_Global) {
      this.page = this.globPageSize.pageSize_Global["pageIndex"];
      this.pageSize = this.globPageSize.pageSize_Global["pageSize"];
    } else {
      this.page = 0;
      this.pageIndex = 0;
    }
    this.globRecentCat.recent_global = "";
    this.globRecentCat.recentSrc_global = "";
    if (this.selectedSeverity == undefined) {
      this.selectedSeverity = "all";
    }
  }

  autoSuggestForAllDownload = [];
  orgId: any;
  totalElementsExcel = 0;

  downloadAllProjectVul() {
    this.snackbarService.open(
      "It will take few minutes to download all project vulnerabilities.",
      "OK",
      30000
    );

    this.ExportTOAllExcel();
  }

  ExportTOAllExcel() {
    this.handler.activateLoader();
    this.projectService
      //.getAutoSuggestionsForAllDownload(this.orgId, this.page, 100, this.sortString, this.sortType, this.category, this.selectedSeverity)
      .getOrgVulnerabilities(
        this.page,
        1000,
        this.sortString,
        this.sortType,
        this.selectedSeverity,
        "",
        ""
      )
      .subscribe((results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        var tmpAutoSuggestForAllDownload = results["data"];
        this.totalElementsExcel = results["totalElements"];

        this.autoSuggestForAllDownload = this.autoSuggestForAllDownload.concat(
          tmpAutoSuggestForAllDownload
        );

        if (this.autoSuggestForAllDownload.length < this.totalElementsExcel) {
          this.page = this.page + 1;
          this.ExportTOAllExcel();
        }
        if (this.autoSuggestForAllDownload.length == this.totalElementsExcel) {
          this.loadExcelDataForAllProject(
            this.autoSuggestForAllDownload,
            this.project.org.name
          );
        }

        // this.loadExcelData(this.project.org.name);
      });
  }

  writeToExcel() {}

  ExportTOExcel() {
    this.storedData();
    this.handler.activateLoader();
    this.projectService
      .getAutoSuggestionsForDownload(
        this.id,
        this.toggleValue,
        this.selectedEnv,
        this.selectedSeverity,
        this.category
      )
      .subscribe((results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.autoSuggestForDownload = results["data"];
        this.loadExcelData(this.autoSuggestForDownload, this.project.name);
        // this.autoSuggestDSForDownload = new MatTableDataSource(this.autoSuggest);
      });
  }

  autoSuggestForDownload: any = null;
  arrSelectedValues = [];
  headers = {};
  loadExcelDataForAllProject(tmpAutoSuggestForDownload, fileName) {
    //  this.loadExcelData();
    this.ExcelData = [];
    this.arrSelectedValues = [];
    this.headers = {};
    this.headers["Project Name"] = "";
    this.headers["OWASP Rank"] = "";
    this.headers["Method"] = "";
    this.headers["Endpoint"] = "";
    this.headers["Resource Name"] = "";
    this.headers["Category"] = "";
    this.headers["CVSS 3.1"] = "";
    this.headers["Severity"] = "";
    this.headers["Since"] = "";
    this.headers["Auth"] = "";
    this.headers["Status"] = "";
    this.headers["Status Code"] = "";
    this.headers["Playbook Name"] = "";
    this.headers["Bug Bounty Savings"] = "";
    this.ExcelData = tmpAutoSuggestForDownload;
    if (this.ExcelData && this.ExcelData.length > 0) {
      for (let i = 0; i < this.ExcelData.length; i++) {
        let vulTime = "";
        vulTime = moment(this.ExcelData[i].createdDate).fromNow();
        if (this.ExcelData[i].createdDate) {
          this.ExcelData[i]["created_date"] = "";
          this.ExcelData[i]["created_date"] = vulTime;
        }
        this.ExcelData[i]["Project Name"] = this.ExcelData[i]["name"];
        this.ExcelData[i]["Resource Name"] = this.ExcelData[i]["resourceName"];
        this.ExcelData[i]["Method"] = this.ExcelData[i]["method"];
        this.ExcelData[i]["Endpoint"] = this.ExcelData[i]["endPoint"];
        this.ExcelData[i]["Category"] = this.ExcelData[i]["category"];
        this.ExcelData[i]["Since"] = this.ExcelData[i]["created_date"];
        this.ExcelData[i]["Auth"] = this.ExcelData[i]["auth"];
        this.ExcelData[i]["Bug Bounty Savings"] =
          "$" + this.ExcelData[i]["bounty"];
        this.ExcelData[i]["OWASP Rank"] = this.ExcelData[i]["rank"];
        this.ExcelData[i]["CVSS 3.1"] = this.ExcelData[i]["cvssScore"];
        if (tmpAutoSuggestForDownload[i]["status"] == "NEW") {
          this.ExcelData[i]["Status"] = "Open";
        } else if (
          this.ExcelData[i]["status"] == "CLOSED" ||
          this.ExcelData[i]["status"] == "SKIPPED"
        ) {
          this.ExcelData[i]["Status"] = "Closed";
        } else {
          this.ExcelData[i]["Status"] = "Closed";
        }
        this.ExcelData[i]["Severity"] = this.ExcelData[i]["severity"];
        this.ExcelData[i]["Status Code"] = this.ExcelData[i]["respStatusCode"];
        this.ExcelData[i]["Playbook Name"] = this.ExcelData[i]["testSuiteName"];
        // if (this.ExcelData[i]['wireLogs'])
        //     this.ExcelData[i]['Wire-logs'] = this.ExcelData[i]['wireLogs'];
        // else
        //     this.ExcelData[i]['Wire-logs'] = '-';
        // if (this.ExcelData[i]['remediation'])
        //     this.ExcelData[i]['Remediation'] = this.ExcelData[i]['remediation'];
        // else
        //     this.ExcelData[i]['Remediation'] = '-';
        const element = this.ExcelData[i];
        const obj = JSON.parse(JSON.stringify(this.headers));
        Object.keys(obj).forEach(function (key) {
          Object.keys(element).forEach(function (key2) {
            if (key.toLowerCase() == key2.toLowerCase()) {
              obj[key] = element[key2];
            }
          });
        });
        this.arrSelectedValues.push(obj);
        //Sort by Category, and then Severity
      }

      this.arrSelectedValues.sort(function (a: any, b: any) {
        const aa = a["Severity"].toString().split(/(\d+)/);
        const bb = b["Severity"].toString().split(/(\d+)/);
        const aaname = a["Category"].toString().split(/(\d+)/);
        const bbname = b["Category"].toString().split(/(\d+)/);

        for (let x = 0; x < Math.max(aa.length, bb.length); x++) {
          if (aa[x] != bb[x]) {
            const cmp1 = isNaN(parseInt(aa[x], 10))
              ? aa[x]
              : parseInt(aa[x], 10);
            const cmp2 = isNaN(parseInt(bb[x], 10))
              ? bb[x]
              : parseInt(bb[x], 10);
            if (cmp1 == undefined || cmp2 == undefined)
              return aa.length - bb.length;
            else return cmp1 < cmp2 ? -1 : 1;
          }
        }

        for (let x = 0; x < Math.max(aaname.length, bbname.length); x++) {
          if (aaname[x] != bbname[x]) {
            const cmp1name = isNaN(parseInt(aaname[x], 10))
              ? aaname[x]
              : parseInt(aaname[x], 10);
            const cmp2name = isNaN(parseInt(bbname[x], 10))
              ? bbname[x]
              : parseInt(bbname[x], 10);
            if (cmp1name == undefined || cmp2name == undefined)
              return aaname.length - bbname.length;
            else return cmp1name < cmp2name ? -1 : 1;
          }
        }
        return 0;
      });

      let objectMaxLength = [];
      for (let i = 0; i < this.arrSelectedValues.length; i++) {
        let value = <any>Object.values(this.arrSelectedValues[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 14;
          } else {
            if (value[j] && value[j].length) {
              objectMaxLength[j] =
                objectMaxLength[j] >= value[j].length
                  ? objectMaxLength[j]
                  : value[j].length;
            }
          }
        }
      }

      // var wscols = [
      //     { width: objectMaxLength[0] },
      //     { width: objectMaxLength[1] = '12' },  // first column
      //     { width: objectMaxLength[2] = '10' },  // second column
      //     { width: objectMaxLength[3] = '25' },
      //     { width: objectMaxLength[4] = '18' },
      //     { width: objectMaxLength[5] = '17' },
      //     { width: objectMaxLength[6] = '10' },
      //     { width: objectMaxLength[7] = '10' },
      //     { width: objectMaxLength[8] = '15' },
      //     { width: objectMaxLength[9] = '15' },
      //     { width: objectMaxLength[10] = '10' },
      //     { width: objectMaxLength[11] = '12' },
      //     { width: objectMaxLength[12] = '22' },
      //     { width: objectMaxLength[13] = '17' },
      //     { width: objectMaxLength[14] = '25' },
      //     { width: objectMaxLength[15] }
      // ];

      // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrSelectedValues);
      // worksheet["!cols"] = wscols;
      // const workBook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      // if (this.globalProjectDetails.name) {
      //     let xlFileName = fileName + '_Vulnerabilities.xlsx';
      //     XLSX.writeFile(workBook, xlFileName, { bookType: 'xlsx', type: 'buffer' }); // initiate a file download in browser
      // }
      downloadCsv(
        this.arrSelectedValues,
        "",
        fileName + "_Vulnerabilities.csv"
      );
    } else {
      // alert('No Vulnerability Available');
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        width: "580px",
        height: "210px",
        data: ["", "No Vulnerability Available"],
      });
    }
  }
  loadExcelData(tmpAutoSuggestForDownload, fileName) {
    //  this.loadExcelData();
    this.ExcelData = [];
    this.arrSelectedValues = [];
    this.headers = {};
    this.headers["Project Name"] = "";
    this.headers["OWASP Rank"] = "";
    this.headers["Method"] = "";
    this.headers["Endpoint"] = "";
    this.headers["Resource Name"] = "";
    this.headers["Category"] = "";
    this.headers["CVSS 3.1"] = "";
    this.headers["Severity"] = "";
    this.headers["Since"] = "";
    this.headers["Auth"] = "";
    this.headers["Status"] = "";
    this.headers["Status Code"] = "";
    this.headers["Playbook Name"] = "";
    this.headers["Bug Bounty Savings"] = "";
    this.headers["Wire-logs"] = "";
    // this.headers['Remediation'] = '';
    // this.headers['Wire-logs'] = '';
    this.ExcelData = tmpAutoSuggestForDownload;
    if (this.ExcelData && this.ExcelData.length > 0) {
      for (let i = 0; i < this.ExcelData.length; i++) {
        let vulTime = "";
        vulTime = moment(this.ExcelData[i].createdDate).fromNow();
        if (this.ExcelData[i].createdDate) {
          this.ExcelData[i]["created_date"] = "";
          this.ExcelData[i]["created_date"] = vulTime;
        }
        this.ExcelData[i]["Project Name"] = this.ExcelData[i]["projectName"];
        this.ExcelData[i]["Resource Name"] = this.ExcelData[i]["resourceName"];
        this.ExcelData[i]["Method"] = this.ExcelData[i]["method"];
        this.ExcelData[i]["Endpoint"] = this.ExcelData[i]["endPoint"];
        this.ExcelData[i]["Category"] = this.ExcelData[i]["category"];
        this.ExcelData[i]["Since"] = this.ExcelData[i]["created_date"];
        this.ExcelData[i]["Auth"] = this.ExcelData[i]["auth"];
        this.ExcelData[i]["Bug Bounty Savings"] =
          "$" + this.ExcelData[i]["bounty"];
        this.ExcelData[i]["OWASP Rank"] = this.ExcelData[i]["rank"];
        this.ExcelData[i]["CVSS 3.1"] = this.ExcelData[i]["cvssScore"];
        if (tmpAutoSuggestForDownload[i]["status"] == "NEW") {
          this.ExcelData[i]["Status"] = "Open";
        } else if (
          this.ExcelData[i]["status"] == "CLOSED" ||
          this.ExcelData[i]["status"] == "SKIPPED"
        ) {
          this.ExcelData[i]["Status"] = "Closed";
        } else {
          this.ExcelData[i]["Status"] = "Closed";
        }
        this.ExcelData[i]["Severity"] = this.ExcelData[i]["severity"];
        this.ExcelData[i]["Status Code"] = this.ExcelData[i]["respStatusCode"];
        this.ExcelData[i]["Playbook Name"] = this.ExcelData[i]["testSuiteName"];
        if (this.ExcelData[i]["wireLogs"]) {
          if (this.ExcelData[i]["wireLogs"].length > 32766) {
            this.ExcelData[i]["Wire-logs"] =
              this.ExcelData[i]["wireLogs"].substring(1, 32700) +
              " ... [Truncated]";
          } else this.ExcelData[i]["Wire-logs"] = this.ExcelData[i]["wireLogs"];
        } else this.ExcelData[i]["Wire-logs"] = "-";
        // if (this.ExcelData[i]['remediation'])
        //     this.ExcelData[i]['Remediation'] = this.ExcelData[i]['remediation'];
        // else
        //     this.ExcelData[i]['Remediation'] = '-';
        const element = this.ExcelData[i];
        const obj = JSON.parse(JSON.stringify(this.headers));
        Object.keys(obj).forEach(function (key) {
          Object.keys(element).forEach(function (key2) {
            if (key.toLowerCase() == key2.toLowerCase()) {
              obj[key] = element[key2];
            }
          });
        });
        this.arrSelectedValues.push(obj);
        //Sort by Category, and then Severity
      }

      this.arrSelectedValues.sort(function (a: any, b: any) {
        const aa = a["Severity"].toString().split(/(\d+)/);
        const bb = b["Severity"].toString().split(/(\d+)/);
        const aaname = a["Category"].toString().split(/(\d+)/);
        const bbname = b["Category"].toString().split(/(\d+)/);

        for (let x = 0; x < Math.max(aa.length, bb.length); x++) {
          if (aa[x] != bb[x]) {
            const cmp1 = isNaN(parseInt(aa[x], 10))
              ? aa[x]
              : parseInt(aa[x], 10);
            const cmp2 = isNaN(parseInt(bb[x], 10))
              ? bb[x]
              : parseInt(bb[x], 10);
            if (cmp1 == undefined || cmp2 == undefined)
              return aa.length - bb.length;
            else return cmp1 < cmp2 ? -1 : 1;
          }
        }

        for (let x = 0; x < Math.max(aaname.length, bbname.length); x++) {
          if (aaname[x] != bbname[x]) {
            const cmp1name = isNaN(parseInt(aaname[x], 10))
              ? aaname[x]
              : parseInt(aaname[x], 10);
            const cmp2name = isNaN(parseInt(bbname[x], 10))
              ? bbname[x]
              : parseInt(bbname[x], 10);
            if (cmp1name == undefined || cmp2name == undefined)
              return aaname.length - bbname.length;
            else return cmp1name < cmp2name ? -1 : 1;
          }
        }
        return 0;
      });

      let objectMaxLength = [];
      for (let i = 0; i < this.arrSelectedValues.length; i++) {
        let value = <any>Object.values(this.arrSelectedValues[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 14;
          } else {
            if (value[j] && value[j].length) {
              objectMaxLength[j] =
                objectMaxLength[j] >= value[j].length
                  ? objectMaxLength[j]
                  : value[j].length;
            }
          }
        }
      }

      var wscols = [
        { wch: objectMaxLength[0] },
        { wch: (objectMaxLength[1] = "12") }, // first column
        { wch: (objectMaxLength[2] = "10") }, // second column
        { wch: (objectMaxLength[3] = "25") },
        { wch: (objectMaxLength[4] = "18") },
        { wch: (objectMaxLength[5] = "17") },
        { wch: (objectMaxLength[6] = "10") },
        { wch: (objectMaxLength[7] = "10") },
        { wch: (objectMaxLength[8] = "15") },
        { wch: (objectMaxLength[9] = "15") },
        { wch: (objectMaxLength[10] = "10") },
        { wch: (objectMaxLength[11] = "12") },
        { wch: (objectMaxLength[12] = "22") },
        { wch: (objectMaxLength[13] = "17") },
        { wch: (objectMaxLength[14] = "25") },
        { wch: (objectMaxLength[15] = "40") },
      ];
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.arrSelectedValues
      );
      worksheet["!cols"] = wscols;
      const workBook: XLSX.WorkBook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      if (this.globalProjectDetails.name) {
        let xlFileName = fileName + "_Vulnerabilities.csv";
        XLSX.writeFile(workBook, xlFileName, {
          bookType: "csv",
          type: "buffer",
        }); // initiate a file download in browser
      }
      // downloadCsv(this.arrSelectedValues, '', fileName + '_Vulnerabilities.csv');
    } else {
      // alert('No Vulnerability Available');
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        width: "580px",
        height: "210px",
        data: ["", "No Vulnerability Available"],
      });
    }
  }

  showVul(e) {
    const tempData: any = JSON.stringify(this.renderedData);
    localStorage.setItem("vulname", tempData);
    this.catSearchBackData = null;
    if (this.category == "All") {
      this.category = "";
    }
    this.catSearchBackData = {
      category: this.category,
      toggleValue: this.toggleValue,
      keyword: this.keyword,
      page: this.page,
      pageSize: this.pageSize,
      activeCount: this.activeCount,
      severity: this.selectedSeverity,
    };
    this.globCatFilter.catResult = this.catSearchBackData;
    this.router.navigate([
      "/app/vulnerabilities/projects/",
      this.id,
      "dashboard",
      e.id,
      "details",
    ]);
  }
  lastScanFalsePositive: number = 0;
  lastScanOpenVul: number = 0;
  lastScanClosedVul: number = 0;
  allFPCount = 0;
  allFNCount = 0;
  getInvalidTestCount() {
    this.handler.activateLoader();
    this.projectService.invalidTestCounts(this.id, this.selectedEnv).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.allFPCount = results["data"]["allTimeFalsePositiveCount"];
        this.allFNCount = results["data"]["allTimeFalseNegativeCount"];
        this.lastScanFalsePositive = results["data"]["runFalsePositiveCount"];
        if (this.lastScanFalsePositive == null) {
          this.lastScanFalsePositive = 0;
        }
        if (results["data"]["lastScanCount"] != null) {
          this.lastScanOpenVul = results["data"]["lastScanCount"]["open"];
          this.lastScanClosedVul = results["data"]["lastScanCount"]["close"];
        }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  getJobs() {
    // this.handler.activateLoader();
    this.jobsService.getJobs(this.id, 0, 50).subscribe(
      (results) => {
        // this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.jobs = results["data"];
        // if(this.jobs.length >0){
        //   this.nextFire=this.jobs[0]['nextFire'];
        // }
        for (const j of this.jobs) {
          if (j.name == "Master") {
            this.jobId = j["id"];
            this.masterJob = j;
            // this.getRunsStatus(this.jobId);
            this.getLatestScanDetails(this.jobId);
            this.nextFire = j["nextFire"];
          }
        }
        if (this.jobs.length > 0) {
          // this.btnScanDisable = true;
          // this.jobId = this.jobs[0]['id'];
          // this.getRunsStatus(this.jobId);
          setTimeout((s) => {
            if (this.taskRunResult["status"] == "PROCESSING") {
              const timer1 = timer(10000, 10000);
              this._clockSubscription = timer1.subscribe((t) => {
                this.loadSuggestions(this.id, this.toggleValue);
                this.loadCategoriesCount(this.id, this.toggleValue);
                this.loadBountyValue(this.id);
                this.getInvalidTestCount();
                // this.vulnerabilityGraph(this.id);
                this.getVulnerbySeverity();
                // this.getTotalRunsCount();
                // this.getRunsStatus(this.jobId);
                this.getLatestScanDetails(this.jobId);
              });
            }
          }, 3000);
        } else {
          this.taskRunResult["status"] = "NA";
          setTimeout((s) => {
            this.getMasterJob();
          }, 2000);
        }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  advRun() {
    if (this.masterJob != null) {
      this.envList.map((e) => {
        if (e.id == this.selectedEnv) {
          this.selectedEnvObj = e;
          return;
        }
      });
      const dialogRef = this.dialog.open(AdvRunComponent, {
        width: "800px",
        data: [this.masterJob, this.selectedEnvObj],
      });
      dialogRef.componentInstance.emitData.subscribe((result) => {
        this.router.navigate([
          "/app/projects/",
          this.project.id,
          "profiles",
          result[0]["job"]["id"],
          "runs",
          result[0]["id"],
        ]);
      });
      // dialogRef.afterClosed().subscribe(result => {
      //     setTimeout(s2 => {
      //         this.getJobs();
      //     }, 4000);
      // });
    } else {
      // alert('Master profile not available')
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        width: "580px",
        height: "210px",
        data: ["", "Master profile not available"],
      });
      setTimeout(() => {
        this.getMasterJob();
      }, 4000);
    }
  }

  getMasterJob() {
    this.jobsService.getJobs(this.id, this.page, this.pageSize).subscribe(
      (results) => {
        // this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.jobs = results["data"];
        for (const j of this.jobs) {
          if (j.name == "Master") {
            this.jobId = j["id"];
            this.masterJob = j;
            this.nextFire = j["nextFire"];
          }
        }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  showHistory() {
    if (this.masterJob != null) {
      //mixpanel code starts
      var obj = {
        projectName: this.project.name,
      };
      this.mixpanelService.trackMixpanel("AllScans", obj);
      //mixpanel code ends

      // this.router.navigate(['/app/projects/', this.id, 'profiles', this.jobId, 'runs']);
      this.router.navigate(["/app/projects/", this.id, "allScans"]);
    } else {
      // alert('Master profile not available')
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        width: "580px",
        height: "210px",
        data: ["", "Master profile not available"],
      });
    }
  }

  showActivities() {
    var obj = {
      projectName: this.project.name,
    };
    this.mixpanelService.trackMixpanel("ProjActivities", obj);
    //mixpanel code ends
    this.router.navigate(["/app/activities/activity/", this.id]);
  }

  playbookSnackBar = false;
  getPlaybookCreationStatus() {
    this.tasksService.getEventStatus(this.id, "Sync").subscribe((results) => {
      this.handler.hideLoader();
      if (results["errors"]) {
        return;
      }
      this.taskPlaybookResult = results["data"];
      if (
        this.taskPlaybookResult["status"] === "In_progress" &&
        !this.playbookSnackBar
      ) {
        this.playbookSnackBar = true;
        this.snackbarService.openSnackBarFromComp(
          "Playbook task in progress... This will take a few minutes to complete.",
          5000,
          "START"
        );
      }

      if (
        this._clockSubscription2 &&
        !(this.taskPlaybookResult["status"] == "In_progress")
      ) {
        // this.playbookSnackBar = false;
        this._clockSubscription2.unsubscribe();
        // this.snackbarService.openSnackBarFromComp(
        //   "Playbooks task completed. ",
        //   5000,
        //   "DONE"
        // );
      }
    });
  }

  scanNumberLink;
  getLatestScanDetails(jobId) {
    this.handler.activateLoader();
    this.runService.getLatestScanDetails(jobId).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        if (results["data"]) {
          this.runId = "#" + results["data"]["runId"];
          this.taskRunResult = results["data"]["task"];
          this.run = results["data"];
          this.scanNumberLink =
            "/app/projects/" +
            this.project.id +
            "/profiles/" +
            this.jobId +
            "/runs/" +
            results["data"]["id"];
        } else {
          this.taskRunResult["status"] = null;
        }
        if (
          this._clockSubscription &&
          !(this.taskRunResult["status"] == "PROCESSING")
        )
          this._clockSubscription.unsubscribe();
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  // getRunsStatus(id) {
  //     this.tasksService.getEventStatus(id, "Run").subscribe(results => {
  //         this.handler.hideLoader();
  //         if (results["errors"]) {
  //             this.taskRunResult['status'] = "NA";
  //             return;
  //         }
  //         this.runId = '#';
  //         if (results['data'].status == 'In_progress') {
  //             this.tempRunResult = results['data'];
  //             this.validateStatus(results['data']);
  //         } else {
  //             this.taskRunResult = results['data'];
  //             if (this.taskRunResult.taskId != null)
  //                 this.getRunById(this.taskRunResult.taskId);
  //         }
  //         if (this.taskRunResult['name'] != null)
  //             this.runId = this.runId + this.taskRunResult['name'].split('/')[2];
  //         if (this._clockSubscription && !(this.taskRunResult['status'] == 'In_progress'))
  //             this._clockSubscription.unsubscribe();
  //     });
  // }

  // validateStatus(result): void {
  //     var d1 = moment()
  //     var d2 = moment(result.modifiedDate)
  //     var minutesDiff = Math.abs(d1.diff(d2, 'minutes'));
  //     this.taskRunResult = this.tempRunResult
  //     if (minutesDiff > 5) {
  //         this.runService.getById(result.taskId).subscribe(results => {
  //             if (this.tempRunResult.status != results['data'].task.status)
  //                 this.tempRunResult.status = results['data'].task.status;
  //             this.taskRunResult = this.tempRunResult;
  //         });
  //     }

  // }

  // getRunById(runId) {
  //     this.handler.activateLoader();
  //     this.runService.getDetails(runId).subscribe(
  //         results => {
  //             this.handler.hideLoader();
  //             if (this.handler.handle(results)) {
  //                 return;
  //             }
  //             this.run = results["data"];
  //         },
  //         error => {
  //             this.handler.hideLoader();
  //             this.handler.error(error);
  //         }
  //     );
  // }

  ngOnDestroy(): void {
    localStorage.removeItem("suitename");
    // localStorage.removeItem("testsuitesNames");
    if (this._clockSubscription) this._clockSubscription.unsubscribe();
    else {
      setTimeout((s) => {
        if (this._clockSubscription) this._clockSubscription.unsubscribe();
      }, 3000);
    }
    if (this._clockSubscription2) this._clockSubscription2.unsubscribe();
    else {
      setTimeout((s) => {
        if (this._clockSubscription2) this._clockSubscription2.unsubscribe();
      }, 3000);
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  endpointApiCounter = 0;
  getEndpointsWithCoverage() {
    this.projectService
      .getEndpointsCoverageV2(this.id, this.selectedEnv, this.toggleValue)
      .subscribe((results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.totActGenerators = results["data"]["totalCoverage"];
        this.totalEndpoints = results["data"]["totalEndpoints"];
        this.totalActiveCategories = results["data"]["totalActiveCategories"];
        this.totalAssestCategories = results["data"]["totalAssestCategories"];
        if (this.totalAssestCategories !== 0) {
          this.assest =
            (this.totalAssestCategories * 100) / this.totalActiveCategories;
        }
        if (this.totalEndpoints == 0 && this.endpointApiCounter < 3) {
          this.endpointApiCounter = this.endpointApiCounter + 1;
          setTimeout(() => {
            this.getEndpointsWithCoverage();
          }, 4000);
        }
      });
  }
  // playbookCountSnapshot() {
  //   this.testSuiteService.getPlaybookCountSnapshot(this.id).subscribe(results => {
  //     this.snapshotCount = results["data"];
  //     this.totalPlaybooks = this.snapshotCount[this.snapshotCount.length - 1]['tsCount']
  //   });
  // }
  // getTotalRunsCount() {
  //   this.handler.activateLoader();
  //   this.runService.getRunsCount(this.id).subscribe(
  //     results => {
  //       this.handler.hideLoader();
  //       if (this.handler.handle(results)) {
  //         return;
  //       }
  //       if( results["data"] >= 1000) this.runsCount = ( results["data"] / 1000).toFixed(2) + 'k';
  //       else this.runsCount =results["data"];
  //     },
  //     error => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }
  // getTodoList() {
  //     this.handler.activateLoader();
  //     this.assistantService.getTodoList(this.id).subscribe(results => {
  //             this.handler.hideLoader();
  //             if (this.handler.handle(results)) {
  //                 return;
  //             }
  //             this.assistant1 = results;
  //
  //
  //               for (let a of this.assistant1) {
  //                 if (a.status != "COMPLETED")
  //                     this.pendingPercentage = this.pendingPercentage + a.percentage
  //                else
  //                     this.completionPercentage = this.completionPercentage + a.percentage
  //             }
  //
  //         },
  //         error => {
  //             this.handler.hideLoader();
  //             this.handler.error(error);
  //         })
  // }
  // openTodoDialog(): void {
  //     if (this.pendingPercentage) {
  //         const dialogRef = this.dialog.open(TodoDialogComponent, {
  //             width: "1000px",
  //             data: this.assistant1
  //         });
  //         dialogRef.afterClosed().subscribe(result => {
  //         });
  //     } else
  //         alert("No 'TODO' Task available");
  // }
  getActiveVulnerability() {
    this.actVulnerGraph = null;
    this.activeCategories = [];
    this.activeCategoriesCounts = [];
    // this.handler.activateLoader();
    // this.projectService
    //     .getRecommendationCategoriesCount(this.id, this.toggleValue, this.selectedEnv)
    //     .subscribe(results => {
    //         this.handler.hideLoader();
    //         if (this.handler.handle(results)) {
    //             return;
    //         }
    //         this.categoryCount = results['data'];
    // this.categoryCountType = JSON.parse(JSON.stringify(results["data"]));

    let tmpCategoryCountType = [];
    tmpCategoryCountType = JSON.parse(JSON.stringify(this.categoryCount));
    this.categoryCount.sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    );
    let ctObj = {
      value: "",
      name: "",
    };
    for (const ct of this.categoryCount) {
      // ct.groupBy = (ct.groupBy).charAt(0).toUpperCase() + (ct.groupBy).slice(1);
      // if (ct.groupBy.length > 18) {
      //     // ct.groupBy = (ct.groupBy).replace(/_/g, "\n");
      //     let pos = ct.groupBy.lastIndexOf('_'), otherchar = "\n";
      //     ct.groupBy = ct.groupBy.substring(0, pos) + "\n" + ct.groupBy.substring(pos + 1)
      // }
      const catName = ct.groupBy + "(" + ct.count + ")";
      ctObj = {
        value: ct.count,
        name: catName,
      };
      this.activeCategories.push(catName);
      this.activeCategoriesCounts.push(ctObj);
    }
    this.vulnerList = {
      legendData: this.activeCategories,
      seriesData: this.activeCategoriesCounts,
    };
    this.activeCategoriesCounts.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );
    // Pie chart
    this.actVulnerGraph = {
      title: {
        text: "Open Vulnerabilities by Categories",
        x: "center",
        textStyle: {
          fontSize: 14,
        },
      },
      // toolbox: {
      //     show: true,
      //     feature: {
      //         saveAsImage: {show: true, title: "save"}
      //     }
      // },
      grid: {
        top: "8%",
        bottom: "5%",
        left: "5%",
        right: "10%",
      },
      tooltip: {
        trigger: "item",
        backgroundColor: "white",
        formatter: "{b}",
        textStyle: {
          fontSize: "11",
          color: "grey",
        },
      },
      // Enable drag recalculate
      // calculable: true,
      series: [
        {
          type: "pie",
          radius: "50%",

          // center: ["40%", "55%"],
          // selectedMode: "single",
          data: this.activeCategoriesCounts,
          // label: {
          //     rotate: 45,
          //     textStyle: {
          //         fontSize: 6
          //     }
          // },
          label: {
            rotate: 45,
            normal: {
              show: true,
              textStyle: {
                fontSize: 10,
              },
            },
          },
        },
      ],
    };
    // sort by String owaspRank
    tmpCategoryCountType.sort((a, b) => {
      return a.owaspRank == null
        ? 1
        : a.owaspRank < b.owaspRank
        ? -1
        : a.owaspRank > b.owaspRank
        ? 1
        : a.owaspRank == b.owaspRank
        ? a.label < b.label
          ? -1
          : a.label > b.label
          ? 1
          : 0
        : 0;
    });
    // sort by Int owaspRank
    tmpCategoryCountType.sort((a, b) => {
      return parseInt(a.owaspRank) < parseInt(b.owaspRank)
        ? -1
        : parseInt(a.owaspRank) > parseInt(b.owaspRank)
        ? 1
        : parseInt(a.owaspRank) == parseInt(b.owaspRank)
        ? a.label < b.label
          ? -1
          : a.label > b.label
          ? 1
          : 0
        : 0;
    });
    this.categoryCountType = [];
    tmpCategoryCountType.map((x) => {
      if (!x.label) {
        x.label = x.groupBy;
      }
      const obj = {
        count: x.count,
        groupBy: x.groupBy,
        label: x.label,
        estimate: x.estimate,
        type: x.type,
        owaspRank: x.owaspRank
          ? x.owaspRank.includes("Top")
            ? x.owaspRank
            : "OWASP " + x.owaspRank
          : "",
      };
      this.categoryCountType.push(obj);
    });
    this.totalEstimate = this.categoryCountType
      .map((i) => i.estimate)
      .reduce((a, b) => a + b, 0);
    const obj1 = {
      groupBy: "All",
      count: this.categoryCountType
        .map((i) => i.count)
        .reduce((a, b) => a + b, 0),
      label: "All",
      estimate: this.totalEstimate,
    };
    if (!this.categoryCountType.includes(obj1))
      this.categoryCountType.unshift(obj1);
    // });
  }

  getVulnerbySeverity() {
    this.vulnerbySeverityGraph = null;
    this.vulnerabilitiesbySeverity = [];
    this.handler.activateLoader();
    this.projectService
      .getVulnerabilityBySeverity(this.id, this.selectedEnv)
      .subscribe((results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.vulnerbySeverityRes = results["data"];
        if (this.vulnerbySeverityRes) {
          this.CriticalValue =
            "Critical" + "(" + this.vulnerbySeverityRes["critical"] + ")";
          this.MajorValue =
            "Major" + "(" + this.vulnerbySeverityRes["major"] + ")";
          this.MinorValue =
            "Minor" + "(" + this.vulnerbySeverityRes["minor"] + ")";
          this.TrivialValue =
            "Trivial" + "(" + this.vulnerbySeverityRes["trivial"] + ")";
          this.HighValue =
            "High" + "(" + this.vulnerbySeverityRes["high"] + ")";
          this.MediumValue =
            "Medium" + "(" + this.vulnerbySeverityRes["medium"] + ")";
          this.LowValue = "Low" + "(" + this.vulnerbySeverityRes["low"] + ")";
          this.vulnerabilitiesbySeverity = [
            {
              value: this.vulnerbySeverityRes["critical"],
              name: this.CriticalValue,
              itemStyle: { normal: { color: "#ef5350" } },
            },
            {
              value: this.vulnerbySeverityRes["major"],
              name: this.MajorValue,
              itemStyle: { normal: { color: "#ed8b00" } },
            },
            {
              value: this.vulnerbySeverityRes["minor"],
              name: this.MinorValue,
              itemStyle: { normal: { color: "#d6fe5d" } },
            },
            {
              value: this.vulnerbySeverityRes["trivial"],
              name: this.TrivialValue,
              itemStyle: { normal: { color: "#61affe" } },
            },
            {
              value: this.vulnerbySeverityRes["high"],
              name: this.HighValue,
              itemStyle: { normal: { color: "#FF8535" } },
            },
            {
              value: this.vulnerbySeverityRes["medium"],
              name: this.MediumValue,
              itemStyle: { normal: { color: "#ffc107" } },
            },
            {
              value: this.vulnerbySeverityRes["low"],
              name: this.LowValue,
              itemStyle: { normal: { color: "#868e96" } },
            },
          ];
          this.vulnerabilitiesbySeverity =
            this.vulnerabilitiesbySeverity.filter((x) => x.value > 0);
          // Pie chart
          this.vulnerbySeverityGraph = {
            title: {
              text: "Open Vulnerabilities by Severity",
              textStyle: {
                fontSize: 14,
              },
              left: "center",
            },

            tooltip: {
              trigger: "item",
              backgroundColor: "white",
              formatter: "{b} {d}%",
              textStyle: {
                fontSize: "11",
                color: "grey",
              },
            },

            calculable: true,
            series: [
              {
                type: "pie",

                radius: "50%",
                center: ["45%", "50%"],
                // selectedMode: 'single',
                data: this.vulnerabilitiesbySeverity,
                label: {
                  normal: {
                    show: true,
                    textStyle: {
                      fontSize: "10",
                    },
                    position: "outside",
                  },
                  emphasis: {
                    show: true,
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              },
            ],
          };
        }
      });
  }

  workingPbCount = 0;
  notWorkingPbCount = 0;
  getReviewStatuswise(categoryCountByStatus) {
    this.totalPlaybooks = 0;
    this.autoValue = 0;
    this.manualValue = 0;
    this.workingPbCount = 0;
    this.notWorkingPbCount = 0;

    if (categoryCountByStatus) {
      for (const cs of categoryCountByStatus) {
        if (cs.count) {
          this.totalPlaybooks += cs.count;
          if (cs.groupBy == "AUTO") {
            this.autoValue = cs["percentage"];
            this.workingPbCount += cs.count;
          }
          if (cs.groupBy == "MANUAL") {
            this.manualValue = cs["percentage"];
            this.workingPbCount += cs.count;
          }
          if (cs.groupBy == "PENDING") {
            this.notWorkingPbCount = cs.count;
          }
        }
      }

      this.totalPlaybooks = new Intl.NumberFormat().format(this.totalPlaybooks);

      if (this.autoValue != 0 || this.manualValue != 0) {
        this.totalProofread = this.autoValue + this.manualValue;
      }
    }
  }
  // email feature
  // getSummaryReport() {
  //     this.handler.activateLoader();
  //     this.snackbarService.openSnackBar('Emailing report to ' + this.loggedInUser, '');
  //     this.projectService
  //         .getSummaryReport(this.id)
  //         .subscribe(results => {
  //             if (results["errors"]) {
  //                 this.snackbarService.openSnackBar('Error in sending email!', '');
  //                 return;
  //             }
  //             this.report = results["data"];
  //             if (this.report)
  //                 this.snackbarService.openSnackBar('Email sent succesfully', '');
  //         });
  // }
  // getDetailReport() {
  //     this.handler.activateLoader();
  //     this.snackbarService.openSnackBar('Emailing report to ' + this.loggedInUser, '');
  //     this.projectService
  //         .getDetailReport(this.id)
  //         .subscribe(results => {
  //             if (results["errors"]) {
  //                 this.snackbarService.openSnackBar('Error in sending email!', '');
  //                 return;
  //             }
  //             this.report = results["data"];
  //             if (this.report)
  //                 this.snackbarService.openSnackBar('Email sent succesfully', '');
  //         });
  // }
  viewRbac() {
    const navigationExtras: NavigationExtras = {
      queryParams: { gen: "rbacMap" },
    };
    this.router.navigate(
      ["/app/config-categories/projects", this.id, "categories"],
      navigationExtras
    );
  }
  list: any = null;

  // show logs
  getTestSuiteResponseByName(runId, name: string, generatorType: string) {
    this.handler.activateLoader();
    if (generatorType && generatorType == "task") {
      this.handler.hideLoader();
      this.showLogsDialog(
        "Scan 'RBAC' category, Review RBAC Map & commit ",
        "",
        ""
      );
    } else {
      this.runService.getTestSuiteResponseByName(runId, name).subscribe(
        (results) => {
          this.handler.hideLoader();
          if (this.handler.handle(results)) {
            return;
          }
          this.list = results["data"];
          // localStorage.setItem('testsuitesNames', this.testsuitesNames);
          let msg = "";
          for (let i = 0; i < this.list.length; i++) {
            msg += this.list[i].logs;
          }
          if (this.list && this.list.length > 0) {
            this.passed = this.list[0].totalPassed;
            this.failed = this.list[0].totalFailed;
          }
          this.showLogsDialog(msg, runId, name);
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
    }
  }

  hideOptions = false;
  showLogsDialog(msg, runId, suitName) {
    this.hideOptions = true;
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(MsgDialogComponent, {
        width: "1400px",
        maxWidth: "90vw",
        data: [
          msg,
          suitName,
          this.testsuitesNames,
          this.id,
          this.jobId,
          runId,
          this.passed,
          this.failed,
          this.hideOptions,
        ],
      });
      // dialogRef.afterClosed().subscribe(result => {
      //   localStorage.removeItem("suitename");
      // });
    }
  }
  editorSelect: any = {
    rowText: "",
    validEvent: false,
    text: "",
    exec: false,
    row: 0,
    column: 0,
    prevExecuted: false,
  };
  testsuite: any = null;
  keywordpb = "";
  // show playbook dialog
  // openPlaybook(projectId, suiteName) {
  //     this.id = projectId;
  //     this.keywordpb = suiteName;
  //     this.editorSelect.text = suiteName;
  //     this.selectedCategory(suiteName);
  // }
  // selectedCategory(suiteName) {
  //     this.handler.activateLoader();
  //     if (this.keywordpb === '' && this.category === '') {
  //         return this.list(this.id);
  //     }
  //     let category_ = '';
  //     if (this.category === 'All') {
  //         category_ = '';
  //     } else {
  //         category_ = this.category;
  //     }
  //
  //     this.testSuiteService.searchTestSuite(this.id, category_, '', this.keywordpb, 0, 10).subscribe((results) => {
  //         this.handler.hideLoader();
  //         if (this.handler.handle(results)) {
  //             return;
  //         }
  //         //  this.testsuites = results['data'];
  //         for (let i = 0; i < results['data'].length; i++) {
  //             const k = results['data'][i];
  //             if (k.name === this.editorSelect.text) {
  //                 this.testsuite = k;
  //                 break;
  //             }
  //         }
  //         if (this.testsuite != null) {
  //             this.testsuite.name = suiteName
  //             this.showTestsuiteDialog(this.testsuite.id, this.testsuite.name, this.testsuite.autoGenerated);
  //         }
  //         setTimeout(() => {
  //             this.editorSelect.exec = false;
  //         }, 3000);
  //
  //     }, (error) => {
  //         this.handler.hideLoader();
  //         this.handler.error(error);
  //     });
  // }
  // showTestsuiteDialog(testSuiteID, testSuiteName, autoGenerated) {
  //     const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
  //         width: '1200px',
  //         data: [this.id, testSuiteID, testSuiteName, autoGenerated]
  //     });
  // }
  envList = [];
  selectedEnv = "";
  selectedEnvObj;
  getEnvs() {
    this.taskPlaybookResult["status"] == "In_progress";
    this.envList = [];
    this.handler.activateLoader();
    this.projectService.getEnvsByProjectId(this.id).subscribe((results) => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.envList = results["data"];
      this.envList.map((e) => {
        e["authStatus"] = "Valid";
        e["auths"].map((item) => {
          if (
            item.invalid == true &&
            item.name != "Invalid_Auth" &&
            item.name != "Invalid_Auth_Empty" &&
            item.name != "Invalid_Auth_SQL"
          ) {
            e["authStatus"] = "";
          }
        });
        if (e.name == "Master") {
          this.selectedEnv = e.id;
          this.selectedEnvObj = e;
          return;
        }
      });

      if (this.globalSelectedEnv.envId != "")
        this.selectedEnv = this.globalSelectedEnv.envId;
      else this.globalSelectedEnv.envId = this.selectedEnv;
      // this.loadCategoriesCount(this.id, this.toggleValue);
      this.loadProject(this.id);
      this.globCatFilter.recent_global = "All";
      this.globCatFilter.recentSrc_global = "";
      this.getJobs();
      if (
        this.globCatFilter.catResult != null &&
        (this.globCatFilter.catResult.category ||
          this.globCatFilter.catResult.keyword)
      ) {
        this.keyword = this.globCatFilter.catResult.keyword;
        this.category = this.globCatFilter.catResult.category;
        this.toggleValue = this.globCatFilter.catResult.toggleValue;
        this.page = this.globCatFilter.catResult.page;
        this.pageSize = this.globCatFilter.catResult.pageSize;
        this.activeCount = this.globCatFilter.catResult.activeCount;
        this.selectedSeverity = this.globCatFilter.catResult.severity;
        this.searchByCategory();
      }
      this.getPlaybookCreationStatus();
      this.checkAndUpdateReviewStatuswise();
      this.onChangeEnv();
      // this.getAutopilot();
      setTimeout((s) => {
        if (this.taskPlaybookResult["status"] == "In_progress") {
          const timer2 = timer(10000, 10000);
          this._clockSubscription2 = timer2.subscribe((t) => {
            this.getPlaybookCreationStatus();
            this.checkAndUpdateReviewStatuswise();
          });
        }
      }, 3000);
    });
  }

  onChangeEnv() {
    this.toggleValue = "active";
    // this.globCatFilter.catResult.toggleValue = 'active';

    if (
      this.globCatFilter.catResult != null &&
      (this.globCatFilter.catResult.toggleValue ||
        this.globCatFilter.catResult.keyword)
    ) {
      this.toggleValue = this.globCatFilter.catResult.toggleValue;
    }

    this.globalSelectedEnv.envId = this.selectedEnv;
    if (
      !(
        this.globCatFilter.catResult != null &&
        (this.globCatFilter.catResult.category ||
          this.globCatFilter.catResult.keyword)
      )
    ) {
      this.loadSuggestions(this.id, this.toggleValue);
    }
    this.get("count-archived-auto-suggestions", "archived");
    this.loadBountyValue(this.id);
    this.getInvalidTestCount();
    this.getEndpointsWithCoverage();
    this.loadCategoriesCount(this.id, this.toggleValue);
    this.getActiveVulnerability();
    this.getVulnerbySeverity();
    // this.vulnerabilityGraph(this.id);
  }

  calculateDiff(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          dateSent.getFullYear(),
          dateSent.getMonth(),
          dateSent.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  navigate2Playbooks() {
    localStorage.setItem("playbookType", "error");
    // this.router.navigate(["/app/projects", this.id, "playbooks"]);
  }

  //get category on click
  getClickVulCategory(el: HTMLElement) {
    const clickVulCategory = localStorage.getItem("selCategory");
    if (clickVulCategory)
      this.category = clickVulCategory.replace(/ *\([^)]*\) */g, "");
    if (this.category != "" || this.category != undefined)
      this.searchByCategory();
    el.scrollIntoView({ behavior: "smooth" });
  }

  //get Severity on click
  getClickSeverity(el: HTMLElement) {
    const clickVulSeverity = localStorage.getItem("selCategory");
    if (clickVulSeverity) {
      var selectedSeverity: any = clickVulSeverity.split("(")[0];
      this.selectedSeverity = selectedSeverity;
    }
    if (this.selectedSeverity != "" || this.selectedSeverity != undefined) {
      this.selectedSeverity = selectedSeverity;
      this.searchByCategory();
    }
    el.scrollIntoView({ behavior: "smooth" });
  }

  upgradeSubscription(): void {
    const dialogRef = this.dialog.open(UpgradeComponent, {
      width: "450px",
      disableClose: true,
    });
  }

  synAll: any = null;
  //synIssueTracker
  synIssueTracker() {
    if (this.masterJob.issueTracker.accountType == "FX_Issues") {
      const dialogRef = this.dialog.open(VulnerabilitySyncDialogComponent, {
        data: this.project.id,
        // width: '450px',
      });
    } else {
      this.snackbarService.openSnackBar(
        "Vulnerabilities sync in progress..",
        ""
      );
      this.handler.activateLoader();
      this.projectService.syncVulnerabilitiesWithNewIT(this.id).subscribe(
        (results) => {
          this.handler.hideLoader();
          if (this.handler.handle(results)) {
            return;
          }
          // if (results['data']) {
          //     this.synAll = results['data'];
          //     console.log(this.synAll)
          // }
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
    }
  }

  /* generateData(obList) {
        var list = [];
        var totalElements = 0;
        for (var i = 0; i < obList.length; i++) {
            var tlist = obList[i];
            totalElements = tlist[0];
            //    console.log(tlist);
            var obj = {
                "cvssScore": tlist[1],
                "category": "" + this.checkNullToEmpty(tlist[2]),
                "id": tlist[3],
                "rank": tlist[4],
                "method": tlist[6],
                "path": tlist[7],
                "createdDate": this.checkEmptyValue(tlist[8]),
                "severity": tlist[9],
                "categoryDisplayLabel": "" + this.checkNullToEmpty(tlist[10]),
                "generatorType": this.checkGeneratorType(tlist[2], tlist[11]),
                "testSuiteName": tlist[13],
                "modifiedDate": this.checkEmptyValue(tlist[15]),
                "runId": tlist[14]
            };
            list.push(obj);
        }
        var res = { "data": list, "totalElements": totalElements };
        return res;
    } */
  checkNullToEmpty(obj) {
    if (obj == null) return "";
    else return obj;
  }
  checkEmptyValue(obj) {
    if (obj == "") return null;
    else return obj;
  }
  checkGeneratorType(obj2, obj) {
    if (obj2 == "RBAC") return "task";
    else if (obj2 == "Compliance2" || obj2 == "Compliance1") return "--";
    else return obj;
  }
  mixpanelEvent() {
    //mixpanel code starts
    var obj = {
      projectName: this.project.name,
    };
    this.mixpanelService.trackMixpanel("PlaybooksTabClicked", obj);
    //mixpanel code ends
  }

  autopilotData: any = [];
  autopiloObj = {
    task: null,
    arrtask: [
      {
        taskData: null,
        taskStatus: null,
      },
    ],
  };

  // autopilotResult: any = []
  //autopilot
  // getAutopilot() {
  //     this.handler.activateLoader();
  //     this.projectService.getAutopilot(this.id).subscribe(
  //         results => {
  //             if (this.handler.handle(results)) {
  //                 return;
  //             }
  //             let autopilotData = results['data'];
  //             for (let i of autopilotData) {
  //                 let obj = {}
  //                 if (i.task == "GENERATE_PLAYBOOKS") {
  //                     obj['sNo'] = 1;
  //                     obj['task'] = "Generate Playbooks";
  //                     obj['taskData'] = i.taskData;
  //                     obj['taskStatus'] = i.taskStatus;

  //                 }
  //                 if (i.task == "VALIDATE_BASE_URL") {
  //                     obj['sNo'] = 2;
  //                     obj['task'] = "Validate Base Url"
  //                     if (this.selectedEnvObj) {
  //                         obj['taskData'] = "Environment '" + this.selectedEnvObj['name'] + "'";
  //                     }
  //                     obj['taskStatus'] = i.taskStatus;
  //                 }
  //                 if (i.task == "VALIDATE_AUTH") {
  //                     obj['sNo'] = 3;
  //                     obj['task'] = "Validate Auth";
  //                     obj['taskData'] = i.taskData;
  //                     obj['taskStatus'] = i.taskStatus;

  //                 }
  //                 if (i.task == "TRIGGER_SCAN") {
  //                     obj['sNo'] = 4;
  //                     obj['task'] = "Trigger Scan";
  //                     obj['taskData'] = i.taskData;
  //                     obj['taskStatus'] = i.taskStatus;
  //                 }

  //                 this.autopilotResult.push(obj);
  //             }
  //             this.autopilotResult.sort((a, b) => {
  //                 return a.sNo - b.sNo;
  //             });
  //             console.log(results['data'])
  //             // var genPlaybooksData = [];
  //             // var baseUrlData = [];
  //             // var validateAuthData = [];
  //             // var triggerScanData = [];

  //             // for (let i of autopilotData) {
  //             //     if (i.task == "GENERATE_PLAYBOOKS" && i.taskData != "NEW_PROJECT") {
  //             //         let genPlaybooksObj = {
  //             //             taskData: i.taskData,
  //             //             taskStatus: i.taskStatus,
  //             //         }
  //             //         genPlaybooksData.push(genPlaybooksObj);
  //             //     }

  //             //     if (i.task == "VALIDATE_BASE_URL") {
  //             //         if (i.taskData == null && this.selectedEnvObj) i.taskData = "Environment '" + this.selectedEnvObj['name'] + "'";
  //             //         let baseUrlObj = {
  //             //             taskData: i.taskData,
  //             //             taskStatus: i.taskStatus,
  //             //         }
  //             //         baseUrlData.push(baseUrlObj)
  //             //     }
  //             //     if (i.task == "VALIDATE_AUTH") {
  //             //         let validateAuthObj = {
  //             //             taskData: i.taskData,
  //             //             taskStatus: i.taskStatus,
  //             //         }
  //             //         validateAuthData.push(validateAuthObj)
  //             //     }
  //             //     if (i.task == "TRIGGER_SCAN") {
  //             //         let triggerScanObj = {
  //             //             taskData: i.taskData,
  //             //             taskStatus: i.taskStatus,
  //             //         }
  //             //         triggerScanData.push(triggerScanObj)
  //             //     }
  //             // }
  //             // this.autopilotData = [
  //             //     {
  //             //         "task": "Generate Playbooks",
  //             //         "arr": genPlaybooksData
  //             //     },
  //             //     {
  //             //         "task": "Validate Base Url",
  //             //         "arr": baseUrlData
  //             //     },
  //             //     {
  //             //         "task": "Validate Auth",
  //             //         "arr": validateAuthData
  //             //     },
  //             //     {
  //             //         "task": "Trigger Scan",
  //             //         "arr": triggerScanData
  //             //     }
  //             // ]

  //         },
  //         error => {
  //             this.handler.hideLoader();
  //             this.handler.error(error);
  //         }
  //     );
  // }
}
