import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { JobsService } from '../../services/jobs.service';
import { RunService } from '../../services/run.service';
import { ProjectService } from '../../services/project.service';
import { Base } from '../../models/base.model';
import { Run } from '../../models/run.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsgDialogComponent } from '../dialogs/msg-dialog/msg-dialog.component';
import { Handler } from '../dialogs/handler/handler';
import { Observable, Subject, Subscription } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { listenOnPlayer } from "@angular/animations/browser/src/render/shared";
import { TestsuitEditDialogComponent } from '../dialogs/testsuit-edit-dialog/testsuit-edit-dialog.component';
import {
    CATEGORIES,
    ELASTICSEARCHVALIDATOR,
    CATEGORIES_TYPE_COUNT
} from './../../shared/shared.data';
// import * as moment from 'moment'
import { timer } from 'rxjs';
import { Array } from 'core-js';
import { TestSuiteService } from '../../services/test-suite.service';
import { GlobalSelectedEnv, GlobalProjectDetails } from '../../shared/shared.data';
import { AlertDialogComponent } from '../dialogs/alert-dialog/alert-dialog.component';
import { DeleteDialogComponent } from '../../components/dialogs/delete-dialog/delete-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-run-detail',
    templateUrl: './run-detail.component.html',
    styleUrls: ['./run-detail.component.scss'],
    providers: [
        JobsService,
        RunService,
        ProjectService,
        SnackbarService,
        TestSuiteService
    ]
})
export class RunDetailComponent implements OnInit {
    globalSelectedEnv = GlobalSelectedEnv;
    runList: any;
    categoryCount: any = [];
    categoryCountType: any = [];
    @Output() emitData = new EventEmitter<string[]>();
    run: Run = new Run();
    list;
    suites;
    cat = CATEGORIES_TYPE_COUNT;
    id;
    projectId: string = '';
    jobId: string = '';
    total = 0;
    failed = 0;
    size = 0;
    time = 0;
    duration = 0;
    loggedInUser;
    success = 0;
    length = 0;
    page = 0;
    pageSize = 100;
    isSort: boolean = false;
    pageSizeOptions: number[] = [25, 50, 100];
    project: Base = new Base();
    job: Base = new Base();
    showSpinner: boolean = false;
    keyword: string = '';
    category: string = 'All';
    status: string = '';
    nextRunId: number = 0;
    prevRunId: number = 0;
    runNumResult: any = [];
    runNumbers: any = [];
    i: number = 0;
    totalRuns: number = 0;
    times;
    totalTimeSaved = 0;
    searchFlag: boolean;
    prevKeyword: string = '';
    prevCategory: string = '';
    prevStatus: string = '';
    prevFpfn: string = '';
    wireLogDetails: any = [];
    renderedData;
    passed = 0;
    abacFailed = 0;
    abacPassed = 0;
    hijackPassed = 0;
    hijackFailed = 0;
    currentCategory;
    pageIndex;
    totalSuitesCount;
    report;
    fpfn = '';
    disableReport: boolean;
    falsePositives = 0;
    falseNegatives = 0;
    searchStringValidator = ELASTICSEARCHVALIDATOR;
    tags: string = '';
    displayedColumns: string[] = [
        'suiteName',
        'category',
        'severity',
        'statusCode',
        'size',
        'startTime',
        'time',
        'failed',
        // "failure",
        'analytics'
    ];

    responseHint = [
        {
            'code': '302',
            'text': 'Found (Previously "Moved temporarily")',
            'hint': 'The test environment server is redirecting to another URL.'
        },
        {
            'code': '400',
            'text': 'Bad Request',
            'hint': 'Inspect \t the playbook and check if the request payload is valid (parameters value or data type or format).'
        },
        { 'code': '401', 'text': 'Unauthorized', 'hint': 'Verify if the credentials of the users provided are valid.' },
        {
            'code': '403',
            'text': 'Forbidden',
            'hint': 'The user does not have access to the API endpoint. Please configure valid credentials.'
        },
        {
            'code': '404',
            'text': 'Not Found',
            'hint': 'Inspect the playbook and check if path param value is invalid or header is missing.'
        },
        {
            'code': '405',
            'text': 'Method Not Allowed',
            'hint': 'The playbook uses an unacceptable method in the HTTP request (POST, PUT etc.).'
        },
        {
            'code': '406',
            'text': 'Not Acceptable',
            'hint': 'Inspect the playbook and verify the Accept-Headers values in the request.'
        },
        {
            'code': '408',
            'text': 'Request Timeout',
            'hint': 'Check the test environment server timeout settings and logs.'
        },
        { 'code': '411', 'text': 'Length Required', 'hint': 'Inspect the playbook and check the Content-Length header.' },
        {
            'code': '412',
            'text': 'Precondition Failed',
            'hint': 'Inspect the playbook and check the conditions in the requests header.'
        },
        {
            'code': '415',
            'text': 'Unsupported Media Type',
            'hint': 'Form data, image files etc. are not supported in request body.'
        },
        {
            'code': '422',
            'text': 'Unprocessable Entity',
            'hint': 'Inspect the playbook and correct the Content-Type header.'
        },
        { 'code': '429', 'text': 'Too Many Requests', 'hint': 'Add delay in the scanner (Use rate limit bot).' },
        { 'code': '500', 'text': 'Internal Server Error', 'hint': 'Check the test environment server log for errors.' },
        { 'code': '501', 'text': 'Not Implemented', 'hint': 'Check the test environment server log for errors.' },
        { 'code': '502', 'text': 'Bad Gateway', 'hint': 'Check the test environment server log for errors.' },
        { 'code': '503', 'text': 'Service Unavailable', 'hint': 'Test environment server is down' },
        { 'code': '504', 'text': 'Gateway Timeout', 'hint': 'Check the test environment server log for errors.' },

    ];
    dataSource = null;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    private _clockSubscription: Subscription;
    @ViewChild('openCloseTable') openCloseTable: {
        nativeElement: { scrollIntoView: (arg0: { behavior: string }) => void };
    };
    dialogRef: MatDialogRef<true, ''>;
    sortString: string = 'startTime';
    sortType: string = 'ASC';

    constructor(
        private jobsService: JobsService,
        private runService: RunService,
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private handler: Handler,
        private snackbarService: SnackbarService,
        private testSuiteService: TestSuiteService
    ) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['projectId']) {
                this.projectId = params['projectId'];
                // this.loadProject(this.projectId);
            }
            if (params['jobId']) {
                this.jobId = params['jobId'];
                // this.loadJob(this.jobId);
            }
            if (params['runId']) {
                this.id = params['runId'];

                const timer1 = timer(100, 10000);
                this._clockSubscription = timer1.subscribe(t => {
                    this.getRunById();
                    this.getSummary();
                    this.getCounts();
                    this.getRunStats();
                    setTimeout(s => {
                        if (
                            this.run.task.status == 'COMPLETED' ||
                            this.run.task.status == 'TIMEOUT'
                        ) {
                            this._clockSubscription.unsubscribe();
                        }
                    }, 3000);
                });
            }
        });
        this.loggedInUser = localStorage.getItem('loggedInUser');
        
        this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(700), 
        distinctUntilChanged() 
      )
      .subscribe((keyword) => {
        this.keyword = keyword; 
        this.searchSubject.next(this.keyword)
        this.search(event);
      });
   }

    getRunStats() {

        this.handler.activateLoader();
        this.runService.getRunStats(this.id).subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                // console.log(results);
                this.falseNegatives = results['data']['falseNegatives'];
                this.falsePositives = results['data']['falsePositives'];



            },
            error => {

                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    ngOnDestroy(): void {
        this._clockSubscription.unsubscribe();
        localStorage.removeItem('testsuitesNames');
    }
    globalProjectDetails = GlobalProjectDetails;
    loadProject(id: string) {
        this.projectService.getById(id).subscribe(results => {
            if (this.handler.handle(results)) {
                return;
            }
            this.project = results['data'];
            this.globalProjectDetails.name = this.project.name;
        });
    }

    loadJob(id: string) {
        this.jobsService.getById(id).subscribe(results => {
            if (this.handler.handle(results)) {
                return;
            }
            this.job = results['data'];
        });
    }

    calSum() {
        this.success = 0;
        this.success =
            this.run.task['totalTestCompleted'] / (this.run.task['totalTestCompleted'] + this.run.task['failedTests']);
        if (this.run.task['totalTests'] == 0)
            this.success = 0;
    }

    getRunById() {
        this.handler.activateLoader();
        this.runService.getDetails(this.id).subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.run = results['data'];
                if (this.run['attributes'].TAGS) this.tags = this.run['attributes'].TAGS.split("script:");
                this.calPF();
                this.job = this.run.job;
                this.project = this.job['project'];
                this.globalProjectDetails.name = this.project.name;
                const strMap = new Map();
                for (const k of Object.keys(this.run.stats)) {
                    strMap.set(k, this.run.stats[k]);
                }
                this.abacFailed = 0;
                this.abacPassed = 0;
                this.hijackFailed = 0;
                this.hijackPassed = 0;
                for (const key of Array.from(strMap.entries())) {
                    if (key[0].includes('ABAC')) {
                        if (key[0].includes('Passed')) {
                            this.abacPassed = this.abacPassed + key[1];
                        } else {
                            if (key[0].includes('Failed')) {
                                this.abacFailed = this.abacFailed + key[1];
                            }
                        }
                    } else if (key[0].includes('Hijack')) {
                        if (key[0].includes('Passed')) {
                            this.hijackPassed = this.hijackPassed + key[1];
                        } else {
                            if (key[0].includes('Failed')) {
                                this.hijackFailed = this.hijackFailed + key[1];
                            }
                        }
                    }
                }
                this.calSum();
                this.updatePlaybooks();
            },
            error => {
                if (this._clockSubscription != null) {
                    console.log('Inside error - unsubscribe')
                    this._clockSubscription.unsubscribe();
                }

                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    getTestSuiteResponsesByRunId() {
        this.handler.activateLoader();
        this.runService.getTestSuiteResponses(this.id).subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.list = results['data'];
            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    testsuitesNames: any = null;

    // onSortChange(event) {
    //     const { checked } = event;
    //     this.isSort = checked;
    //     // this.page = 25;
    //     this.getSummary();
    // }
    valList=['Failed','Passed'];
    keyList=['TestSuites_Failed','TestSuites_Passed'];
    calPF(){
        if(this.run.task['status']=='COMPLETED')
        if(this.run.stats['TestSuites_Failed'] +this.run.stats['TestSuites_Passed'] !=this.run.attributes['TESTSUITE_COUNT'])
        for(var i=0;i<this.valList.length;i++)  this.fetchDataForIndex(this.valList[i],this.keyList[i]);      
       
    
}

updatePlaybooks(){
   // this.run.attributes['TESTSUITE_COUNT']=this.PlaybooksStat['TESTSUITE_COUNT'];
    this.run.attributes['TestSuites_Passed']=this.PlaybooksStat['TestSuites_Passed'];
    this.run.attributes['TestSuites_Failed']=this.PlaybooksStat['TestSuites_Failed'];
}
fetchDataForIndex(value, key) {
    this.runService.searchV2( this.id,"", "",value, "",0,1, "startTime", "ASC" )
    .subscribe(
        (results) => {
            this.run.stats[key]=results['totalElements'];
            this.PlaybooksStat[key]=results['totalElements'];
});
  }
  PlaybooksStat ={ "TESTSUITE_COUNT": 0,
  "TestSuites_Passed" :0,
  "TestSuites_Failed":0
  }
    getSummary() {
        this.searchFlag = false;
        this.handler.activateLoader();
        this.runService.getSummaryV2(this.id, this.page, this.pageSize, this.isSort, this.sortString, this.sortType).subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.suites = results['data'];
              //  this.PlaybooksStat['TESTSUITE_COUNT']=results['totalElements'];
               // this.run.attributes['TESTSUITE_COUNT']=this.PlaybooksStat['TESTSUITE_COUNT'];
                if (this.suites) {
                    this.testsuitesNames = this.suites.map(function (item) {
                        return item.suiteName;
                    });
                    localStorage.setItem('testsuitesNames', this.testsuitesNames);
                }

                this.suites.map(a => {
                    this.responseHint.map(r => {
                        if (a.statusCode == r.code) {
                            a['toolTip'] = r.code + ' | ' + r.text + ' | ' + r.hint
                        }
                        // temp code for fp fn icon
                        // if (a['errorResultStatus'])
                        //     a['flaggedByAi'] = true

                    })

                })

                this.totalSuitesCount = results['totalElements'];
                this.length = results['totalElements'];
                this.dataSource = new MatTableDataSource(this.suites);
                this.dataSource.sort = this.sort;
                this.dataSource.connect().subscribe(d => (this.renderedData = d));
                this.calPF();
            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

   onSearchInput(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        if(this.searchStringValidator.isValidSearchKeyCode(inputElement.value)){
          this.searchSubject.next(inputElement.value);
        } 
      }
    private searchSubject = new Subject<string>();
    private searchSubscription: Subscription;
    resultOfPlaybooks: boolean = true;
      

    selectedLabel = '';
    search(event) {
        if (this.category != 'All') {
            this.categoryCountType.map(c => {
                if (c.groupBy == this.category) {
                    if (c.label)
                        this.selectedLabel = c.label
                    else
                        this.selectedLabel = c.groupBy
                }

            })
        }

        if (this.keyword.length == 0 || this.keyword.length >= 1) {
            // this.keyword = this.searchStringValidator.checkSearchString(this.keyword);
            // const key = event.target.value;
            // if (this.searchStringValidator.isValidSearchKeyCode(key)) {
                if (this.prevKeyword != this.keyword) this.searchFlag = true;
                else if (this.prevCategory != this.category && this.category != 'All') this.searchFlag = true;
                else if (this.prevStatus != this.status) this.searchFlag = true;
                else if (this.prevFpfn != this.fpfn) this.searchFlag = true;
                else {
                    this.prevKeyword = this.keyword;
                    this.prevCategory = this.category;
                    this.prevStatus = this.status;
                    this.prevFpfn = this.fpfn;
                    this.searchFlag = false;
                }
                if (this.searchFlag) {
                    this.page = 0;
                    this.paginator.pageIndex = 0;
                    this.searchFlag = false;
                }
                this.prevKeyword = this.keyword;
                this.prevCategory = this.category;
                this.prevStatus = this.status;
                this.prevFpfn = this.fpfn;
                if (this.keyword == '' && this.category == 'All' && this.status == '' && this.fpfn == '') {
                    this.selectedLabel = ''
                    return this.getSummary();
                }

                let tmpCat = '';

                if (this.category == 'All') {
                    this.selectedLabel = ''
                    tmpCat = ''
                }

                else
                    tmpCat = this.category

                this.handler.activateLoader();
                this.resultOfPlaybooks = false;
                this.runService
                    .searchV2(
                        this.id,
                        tmpCat,
                        this.keyword,
                        this.status,
                        this.fpfn,
                        this.page,
                        this.pageSize, this.sortString, this.sortType
                    )
                    .subscribe(
                        results => {
                            this.handler.hideLoader();
                            this.resultOfPlaybooks = true;
                            if (this.handler.handle(results)) {
                                return;
                            }
                            this.suites = results['data'];
                            this.length = results['totalElements'];

                            this.suites.map(a => {
                                this.responseHint.map(r => {
                                    if (a.statusCode == r.code) {
                                        a['toolTip'] = r.code + ' | ' + r.text + ' | ' + r.hint
                                    }

                                })

                            })

                            this.dataSource = new MatTableDataSource(this.suites);
                            this.dataSource.sort = this.sort;
                            this.dataSource.connect().subscribe(d => (this.renderedData = d));
                            this.updatePlaybooks();
                            this.resultOfPlaybooks = true;
                        },
                        error => {
                            this.handler.hideLoader();
                            this.handler.error(error);
                            this.resultOfPlaybooks = false;
                        }
                    );
            // }
        }
    }

    getTestSuiteResponseByName(name: string, runId) {
        this.handler.activateLoader();
        this.runService.getTestSuiteResponseByName(this.id, name).subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.list = results['data'];
                // localStorage.setItem('testsuitesNames', this.testsuitesNames);
                const arrayLength = this.list.length;
                let msg = '';
                for (let i = 0; i < arrayLength; i++) {
                    msg += this.list[i].logs;
                }
                this.passed = this.list[0].totalPassed;
                this.failed = this.list[0].totalFailed;
                this.showDialog(msg, name, runId);
            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    showDialog(msg, suitName, runId) {
        if (this.dialog.openDialogs.length == 0) {
            // for (let i = 0; i < this.suites.length; i++) {
            // this.wireLogDetails[i] = this.renderedData[i].suiteName;
            if (this.suites && this.suites.length > 0) {
                this.wireLogDetails = null;
                this.wireLogDetails = this.suites.map(function (item) {
                    return { "runId": item.runId, "testsuitesName": item.suiteName };
                });
            }
            // }
            const dialogRef = this.dialog.open(MsgDialogComponent, {
                width: '1400px',
                maxWidth: '90vw',
                data: [
                    msg,
                    suitName,
                    this.wireLogDetails,
                    this.projectId,
                    this.jobId,
                    runId,
                    this.passed,
                    this.failed
                ]
            });
            dialogRef.afterClosed().subscribe(result => {
                localStorage.removeItem('suitename');
            });
        }
    }

    change(evt, fromSort: boolean) {
        var selectedEvent: any = event.target;
        this.lastEvent = evt;
        if (selectedEvent.className != "mat-option-text")
            window.scrollTo({ top: 370, behavior: 'smooth' });
        /*
         * @author Shamiulla Shaik
         * Ticket Details: Scan details page sort by failed #120
         */
        if (!fromSort) {
            this.page = evt['pageIndex'];
            this.pageSize = evt.pageSize;
        }
        this.search(event);
    }

    cancel() {
        // const r = confirm('Are you sure you want to Cancel this run?');
        let dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "580px",
            height: "235px",
            data: ["Yes", "Are you sure you want to stop this scan?"]
        });
        dialogRef.componentInstance.emitData.subscribe(data1 => {
            if (data1 == 'Y') {
                this.snackbarService.openSnackBar(
                    "Run Number '" + this.run.runId + "' cancelling...",
                    ''
                );
                this.runService.stopRun(this.id).subscribe(
                    results => {
                        this.handler.hideLoader();
                        if (this.handler.handle(results)) {
                            return;
                        }
                    },
                    error => {
                        this.handler.error(error);
                    }
                );
            }
        })
    }

    deleteRun() {
        // const r = confirm('Are you sure you want to delete this scan?');
        let dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "580px",
            height: "235px",
            data: ["Delete", "Are you sure you want to delete this scan?"]
        });
        dialogRef.componentInstance.emitData.subscribe(data1 => {
            if (data1 == 'Y') {
                this.snackbarService.openSnackBar(
                    "Deleting Scan '" + this.run.runId,
                    ''
                );
                this.runService
                    .deleteByJobIdAndRunId(this.jobId, this.run.runId)
                    .subscribe(
                        results => {
                            if (this.handler.handle(results)) {
                                return;
                            }
                            this.snackbarService.openSnackBar(
                                "Scan '" + this.run.runId + "' deleted successfully!",
                                ''
                            );
                            this.router.navigate([
                                '/app/projects',
                                this.project.id,
                                'allScans'
                            ]);
                        },
                        error => {
                            this.handler.hideLoader();
                            this.handler.error(error);
                        }
                    );
            }
        })
    }

    rerun() {
        this.runService.reRunByJobIdAndRunId(this.jobId, this.run.runId).subscribe(
            results => {
                if (this.handler.handle(results)) {
                    return;
                }
                this.snackbarService.openBackgroundMsgBar(
                    "Rescanning '" + this.run.runId + "'..."
                );
                this.router.navigate([
                    '/app/projects',
                    this.project.id,
                    'allScans'
                ]);
            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    nextRun() {
        this.getDetailsByJobIdRunNumber('next');
    }

    prevRun() {
        this.getDetailsByJobIdRunNumber('prev');
    }

    getDetailsByJobIdRunNumber(action: string) {
        if (this._clockSubscription != null) {
            this._clockSubscription.unsubscribe();
        }
        this.selectedLabel = ''
        this.prevKeyword = '';
        this.prevCategory = '';
        this.prevStatus = '';
        this.prevFpfn = '';
        this.searchFlag = false;
        this.keyword = '';
        this.category = 'All';
        this.status = '';
        this.handler.activateLoader();
        this.runService
            .getDetailsByJobIdRunNum(this.jobId, this.run.runId, action)
            .subscribe(
                runNumRes => {
                    this.handler.hideLoader();
                    if (this.handler.handle(runNumRes)) {
                        return;
                    }
                    this.runNumResult = runNumRes['data'];
                    this.job = this.runNumResult['job'];
                    this.project = this.job['project'];
                    this.globalProjectDetails.name = this.project.name;
                    this.run = this.runNumResult;
                    this.calSum();
                    this.router.navigate([
                        '/app/projects',
                        this.projectId,
                        'profiles',
                        this.job.id,
                        'runs',
                        this.run['id']
                    ]);
                },
                error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                }
            );
    }

    refresh() {
        // this.status = '';
        // this.fpfn = ''
        // this.selectedLabel = ''
        // this.keyword = ''
        // this.category = 'All'
        this.getRunById();
        // this.getSummary();
        this.search("");
        this.getCounts();
        this.getRunStats();
    }

    showTSDialog(testSuiteId, testSuiteName, generator) {
        if (this.dialog.openDialogs.length == 0) {
            const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
                width: '1200px',
                data: [this.projectId, testSuiteId, testSuiteName, generator]
            });
        }
    }

    // Smooth scroll effect for tiles click
    scrollToElement(): void {
        this.openCloseTable.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
    totalEstimate = 0;
    getCounts() {
        this.handler.activateLoader();
        // this.testSuiteService.getCategoryCounts(this.projectId).subscribe(results => {
        this.runService.getTestsuiteCount(this.id).subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.categoryCountType = [];
                let tmpCategoryCountType = [];
                // this.categoryCountType = results["data"];
                tmpCategoryCountType = results['data'];

                // sort by String owaspRank
                tmpCategoryCountType.sort((a, b) => {
                    return (a.owaspRank == null) ? 1 : (a.owaspRank) < (b.owaspRank) ? -1 : (a.owaspRank) > (b.owaspRank) ? 1 : (a.owaspRank) == (b.owaspRank) ? ((a.label) < (b.label) ? -1 : (a.label) > (b.label) ? 1 : 0) : 0;
                }
                )

                // sort by Int owaspRank
                tmpCategoryCountType.sort((a, b) => {
                    return parseInt(a.owaspRank) < parseInt(b.owaspRank) ? -1 : parseInt(a.owaspRank) > parseInt(b.owaspRank) ? 1 : parseInt(a.owaspRank) == parseInt(b.owaspRank) ? ((a.label) < (b.label) ? -1 : (a.label) > (b.label) ? 1 : 0) : 0;
                }
                )


                tmpCategoryCountType.map(x => {
                    const obj = {
                        count: x.count,
                        groupBy: x.groupBy,
                        label: x.label,
                        owaspRank: x.owaspRank ? x.owaspRank.includes('Top') ? x.owaspRank : 'OWASP ' + x.owaspRank : ''
                    }
                    this.categoryCountType.push(obj)
                });
                // this.categoryCountType.sort((a, b) =>
                //     a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                // );



                const obj1 = {
                    groupBy: 'All',
                    count: this.categoryCountType.map(i => i.count).reduce((a, b) => a + b, 0),
                    label: 'All'
                };

                if (!this.categoryCountType.includes(obj1))
                    this.categoryCountType.unshift(obj1);

            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    // getDetailReport() {
    //     this.handler.activateLoader();
    //     this.projectService
    //         .getDetailReportByProjectRun(this.projectId, this.id, "PDF")
    //         .subscribe(results => {
    //             this.handler.hideLoader();
    //             if (results['errors']) {
    //                 // alert('Error in sending email!');
    //                 let dialogRef = this.dialog.open(AlertDialogComponent, {
    //                     width: "580px",
    //                     height: "225px",
    //                     data: ["", "Error in sending email!"]
    //                 });
    //                 return;
    //             }
    //             this.report = results['data'];
    //             if (this.report) {

    //                 // alert('Email sent succesfully');
    //                 let dialogRef = this.dialog.open(AlertDialogComponent, {
    //                     width: "580px",
    //                     height: "225px",
    //                     data: ["", "Email sent succesfully"]
    //                 });
    //             }

    //         });
    // }

    getDetailReport() {
        this.snackbarService.openSnackBar('Emailing report to ' + this.loggedInUser, '');
        this.projectService
            .getDetailReportByProjectRun(this.projectId, this.id, "PDF")
            .subscribe(results => {
                if (results["errors"]) {
                    this.snackbarService.openSnackBar('Error in sending email!', '');
                    return;
                }
                this.report = results["data"];
                if (this.report)
                    this.snackbarService.openSnackBar('Email sent successfully to ' + this.loggedInUser, '');
            },


                error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                });
    }
    getSummaryReport() {
        this.snackbarService.openSnackBar('Emailing report to ' + this.loggedInUser, '');
        this.projectService
            .getSummaryReportByProjectRun(this.projectId, this.id, "PDF")
            .subscribe(results => {
                if (results['errors']) {
                    this.snackbarService.openSnackBar('Error in sending email!', '');
                    return;
                }
                this.report = results['data'];
                if (this.report) {
                    this.snackbarService.openSnackBar('Email sent successfully to ' + this.loggedInUser, '');
                }
            },
                error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                });
    }

    getTooltipMultiLine() {
        return 'Marked as False-Positive by AI Bot. \n Reason: Login/Logout/Sign-up endpoint detected.';
    }

    lastEvent: any;
    changeSort(obj, event) {
        event.stopPropagation();
        this.sortString = obj;
        if (this.sortType == "ASC") this.sortType = "DESC"; else this.sortType = "ASC";
        /*
         * @author Shamiulla Shaik
         * Ticket Details: Scan details page sort by failed #120
         */
        if ((this.keyword && this.keyword.length >= 3) || this.selectedLabel || this.fpfn === 'true'
            || this.status === 'Passed' || this.status === 'Failed') {
            this.change(this.lastEvent, true)
        } else {
            this.getSummary();
        }
    }

    // Calculate the time difference in seconds
    calculateDiff(createDate, closedDate) {
        var date1: any = new Date(createDate);
        var date2: any = new Date(closedDate);
        const timeDifferenceInMillis = Math.abs(date1.getTime() - date2.getTime());
        const diffTime = Math.floor(timeDifferenceInMillis / 1000);
        return diffTime
    }
}
